export enum MatchStatus {
  Deleted = 0,
  Pending = 1,
  NotStarted = 2,

  InPlay = 10,
  Paused = 11,
  Intermission = 12,
  Completed = 15,
  Closed = 16,

  Suspended = 20,
  Canceled = 21,
  WalkOver = 22,
  WO_Home = 23,
  WO_Visitor = 24,
}
