import React from "react";
import { NavLink } from "react-router-dom";
import "./NavItem.css";
import { Icon } from "../../components/Icons/Icon";

interface properties {
  caption: string;
  icon?: string;
  active?: boolean;
  to: string;
}

export const NavItem: React.FC<properties> = ({
  caption: text,
  icon,
  active,
  to,
}) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        isActive ? "sider-button active" : "sider-button"
      }
    >
      {icon && <Icon name={icon as any} color={active ? "white" : "black"} />}
      {text}
    </NavLink>
  );
};
