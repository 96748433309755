import React from "react";
import { useParams } from "react-router-dom";

import MatchTimerButtons from "./MatchTimerButtons";
import EventList from "./EventList";
import { MatchTimerContextProvider } from '../../contexts/MatchTimerContext';
import { MatchTimerHeader } from "./MatchTimerHeader";
import { CardsList } from './Cards/CardsList';
import { TimerProvider } from "../../contexts/TimerContext";

const MatchTimerManagerPage: React.FC = () => {
  const { matchId } = useParams<{ matchId: string }>();

  return (
    <TimerProvider>
      <MatchTimerContextProvider matchId={matchId ?? ""}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "70%",
              marginRight: 10,
              display: "flex",
              flexDirection: "column",
              height: "90vh",
              boxSizing: "border-box",
            }}
          >
            <div style={{ flex: 1 }}>
              <MatchTimerHeader />
              <CardsList />
            </div>

            <MatchTimerButtons />
          </div>
          <div style={{ width: "30%" }}>
            <EventList />
          </div>
        </div>
      </MatchTimerContextProvider>
    </TimerProvider>
  );
};

export default MatchTimerManagerPage;
