import React, { useCallback, useContext, useEffect, useState } from "react";
import { Form, message, Modal } from "antd";
import moment from "moment";
import "./MembersCard.css";
import user from "../../public/default-user.png";
import { ButtonSaveCancel } from "../../components/Form/Buttons/ButtonSaveCancel";
import { MembersContext } from "../../contexts/MembersContext";
import Card from "../../components/Card/Card";
import MembersForm from "./MembersForm";
import { MembersActivities } from "./MembersActivities";
import { ButtonAction } from "../../components/Form/Buttons/ButtonAction";
import { ButtonAdd } from "../../components/Form/Buttons/ButtonAdd";

export const MembersCard: React.FC = () => {
  const [form] = Form.useForm();
  const [popupForm] = Form.useForm();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { current, editing, edit, cancel, update, create } =
    useContext(MembersContext);

  useEffect(() => {
    const formData = {
      ...current,
      datebirth: current?.datebirth ? moment(current.datebirth) : null,
    };
    form.setFieldsValue(formData);
    popupForm.setFieldsValue(formData);
  }, [current, form, popupForm]);

  const handleEditClick = () => {
    setIsPopupVisible(true);
  };

  const handleSaveClick = useCallback((): void => {
    popupForm.submit();
  }, [popupForm]);

  const handleCancelClick = () => {
    setIsPopupVisible(false);
    cancel();
  };

  async function onFinish(values: any): Promise<void> {
    try {
      const result =
        (await update({
          ...values,
          //datebirth: values.datebirth ? values.datebirth.toISOString() : null,
        })) ?? false;

      if (result) {
        messageApi.open({
          type: "success",
          content: "Guardado con éxito",
        });
        setIsPopupVisible(false);
        return;
      }
    } catch (err) {
      console.log(err);
    }
    messageApi.open({
      type: "error",
      content: "Error al guardar.",
    });
  }

  return (
    <Card>
      {contextHolder}
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          height: 48,
          alignItems: "center",
          gap: 8,
        }}
      >
        <ButtonAdd onClick={create} />
        {!editing && (
          <div style={{ marginTop: 8 }}>
            <ButtonAction type="edit" onClick={handleEditClick} />
          </div>
        )}
        {editing && (
          <ButtonSaveCancel
            onSaveClick={handleSaveClick}
            onCancelClick={handleCancelClick}
          />
        )}
      </div>

      <MembersForm
        form={form}
        onFinish={() => {}}
        user={user}
        editing={false}
        current={current}
      />
      <div>
        <span style={{ fontWeight: 500, fontSize: 14, marginLeft: 10 }}>
          Federaciones
        </span>
        <div>
          <MembersActivities />
        </div>
      </div>
      {/* Popup para edición */}
      <Modal
        visible={isPopupVisible}
        title="Editar Miembro"
        onCancel={handleCancelClick}
        footer={
          <ButtonSaveCancel
            onSaveClick={handleSaveClick}
            onCancelClick={handleCancelClick}
          />
        }
      >
        <MembersForm
          form={popupForm}
          onFinish={onFinish}
          user={user}
          editing={true}
          current={current}
        />
      </Modal>
    </Card>
  );
};
