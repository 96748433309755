import React from "react";
import { icons } from "./icons";

interface IconProps {
  name: keyof typeof icons;
  size?: number;
  color?: string;
  className?: string;
}

export const Icon: React.FC<IconProps> = ({
  name,
  size = 18,
  color,
  className,
}) => {
  const SvgIcon = icons[name];
  // TODO: cambiar por ISmpleImage y que las imagenes las levante del path assest dinamicamnete como los escudos
  if (!SvgIcon) {
    console.error(`Icon "${name}" not found`);
    return null;
  }

  return (
    <SvgIcon
      className={className}
      style={{
        width: size,
        height: size,
        fill: color,
      }}
      aria-hidden="true"
    />
  );
};
