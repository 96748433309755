import React, { useEffect, useState } from "react";
import { Form, Row, Col, Divider } from "antd";
import { FormItem } from "../../components/Form/FormItem/FormItem";
import { MyAccountService } from "../../services/MyAccopuntService";
import Input from "../../components/Form/Input/Input";
import { getEnumName } from "../../virtualclub/utils/helpers/EnumHelper";
import { configApp } from "../../services/configApp";
import { Avatar } from "../../components/Avatar/Avatar";
import { Account } from "../../virtualclub/models/models/accounts/Account";
import { AccountType } from "../../virtualclub/models/models/accounts/AccountType.enum";
import { useFileService } from '../../services/useFileService';
import { ButtonAction } from '../../components/Form/Buttons/ButtonAction';

const AccountForm: React.FC<{
  onSubmit?: (data: Account) => void;
}> = ({ onSubmit }) => {
  const [form] = Form.useForm();
  const { changeAvatar } = useFileService();
  const [account, setAccount] = useState<Account>();
  const [edit, setEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const url = configApp.avatarUrl + account?.accountId + ".png";

  useEffect(() => {
    const fetchAccount = async () => {
      setLoading(true);
      const accountData = await MyAccountService.get();
      setAccount(accountData);
      form.setFieldsValue(accountData);
      setLoading(false);
    };

    fetchAccount();
  }, [form]);

  const handleSelectChange = (value: number) => {
    // setCuenta({ ...cuenta, condicionIva: value });
  };

  const handleSubmit = () => {
    // form.validateFields().then((values) => {
    //   if (onSubmit) {
    //     onSubmit({ ...cuenta, ...values });
    //   }
    // });
  };
  const handleEditClick = () => {
    setEdit(true);
  };

  async function handleChangeImage(file: File, fileType: string): Promise<void> {

    if (account?.accountId) {
      const fileUrl = await changeAvatar(account?.accountId, file, fileType);

    }
  }


  return (
    <div className="account-card">
      {!edit && <ButtonAction type="edit" onClick={handleEditClick} />}

      <div className="card-banner" style={{ backgroundColor: "red" }}></div>
      <div className="card-content">
        <Form
          form={form}
          layout="vertical"
          initialValues={account}
        // onFinish={handleSubmit}
        >
          <div className="profile-pic">
            <Avatar imageId={account?.accountId} canChangeImage size={100} onImageUpload={handleChangeImage} />
          </div>
          <div className="header-form">
            <Row gutter={16}>
              <Col span={12}>
                <FormItem
                  label="Nombre"
                  name="accountName"
                  rules={[
                    { required: true, message: "Por favor, ingrese el nombre" },
                  ]}
                >
                  <Input name="accountName" disabled={!edit} />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="Tipo de Cuenta">
                  <Input
                    name="accountType"
                    value={
                      account?.accountType
                        ? getEnumName(AccountType, account?.accountType)
                        : "--"
                    }
                    disabled={true}
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="ID Externo" name="externalId">
                  <Input name="externalId" disabled={true} />
                </FormItem>
              </Col>
            </Row>
          </div>
          {/* <Divider orientation="left">Datos fiscales</Divider>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem label="CUIT" name="cuit">
                <Input name="cuit" disabled={!edit} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Razón Social" name="razonSocial">
                <Input name="razonSocial" disabled={!edit} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Condición IVA" name="condicionIva">
                <Select onChange={handleSelectChange} disabled={!edit}>
                  <Option value={1}>Responsable Inscripto</Option>
                  <Option value={2}>Monotributista</Option>
                  <Option value={3}>Exento</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Ingresos Brutos" name="ingresosBrutos">
                <Input name="ingresosBrutos" disabled={!edit} />
              </FormItem>
            </Col>
          </Row>
          <Divider orientation="left">Domicilio fiscal</Divider>

          <Row gutter={16}>
            <Col span={12}>
              <FormItem label="Domicilio" name="domicilio">
                <Input name="domicilio" disabled={!edit} />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Localidad" name="localidad">
                <Input name="localidad" disabled={!edit} />
              </FormItem>
            </Col>
            <Col span={4}>
              <FormItem label="Código Postal" name="codigoPostal">
                <Input name="codigoPostal" disabled={!edit} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Provincia" name="provincia">
                <Input name="provincia" disabled={!edit} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="País" name="pais">
                <Input name="pais" disabled={!edit} />
              </FormItem>
            </Col>
          </Row> */}

          <Divider orientation="left">Redes sociales</Divider>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem label="Email" name="email">
                <Input name="email" disabled={!edit} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Facebook / Meta" name="facebook">
                <Input name="facebook" disabled={!edit} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Instagram" name="instagram">
                <Input name="instagram" disabled={!edit} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Twitter / X" name="twiter">
                <Input name="twiter" disabled={!edit} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="WhatsApp" name="whatsapp">
                <Input name="whatsapp" disabled={!edit} />
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default AccountForm;
