import React, { useCallback } from "react";
import { SportTeamCategoryType } from "../../virtualclub/models/models/sportsTeamsCategories/SportTeamCategoryType.enum";
import { NamesHelper } from "../../helpers/NamesHelper";
import "./SelectSportTeam.css";
import { ReactComponent as SHIRT } from "../../assets/shirts/cs3.svg";

import { Icon } from "../../components/Icons/Icon";

interface properties {
  sportTeamCategoryType: SportTeamCategoryType;
  value?: string;
  onClick?: (value: SportTeamCategoryType) => void;
  showShirt?: boolean;
  style?: React.CSSProperties;
}

export const SelectSportTeam: React.FC<properties> = ({
  value,
  sportTeamCategoryType,
  onClick,
  showShirt = false,
  style
}) => {
  const handleSearchClick = useCallback(() => {
    onClick?.(sportTeamCategoryType);
  }, [onClick, sportTeamCategoryType]);

  return (
    <div
      className="sportTeamSelect"
      onClick={handleSearchClick}
      style={
        !showShirt
          ? {
            borderLeft: `4px solid ${NamesHelper.CategoryTypeColor(
              sportTeamCategoryType
            )}`,
          }
          : undefined
      }
    >
      {showShirt ? (
        <SHIRT
          className="sportTeamShirt"
          style={{
            ...style,
            color: NamesHelper.CategoryTypeColor(sportTeamCategoryType),
          }}
        />
      ) : null}
      <div className="sportTeamInfo">
        <div className="sportTeamDetails">
          <span className="categoryType">
            {SportTeamCategoryType[sportTeamCategoryType ?? 0]}
          </span>
          <span className="playerName">{value ?? "no seleccionado"}</span>
        </div>
        <Icon name="search" />
      </div>
    </div>
  );
};
