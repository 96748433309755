import React, { useContext } from "react";
import { Form, Row, Col, Divider } from "antd";
import { FormInstance } from "antd/lib/form";
import { FormItem } from "../../components/Form/FormItem/FormItem";
import Input from "../../components/Form/Input/Input";
import { MemberCategoriesView } from "../../virtualclub/models/models/members/MemberCategories.view";
import { FormItemHidden } from "../../components/Form/FormItem/FormItemHidden";
import { SelectGenre } from "../../components/lookups/SelectGenre";
import { ReactComponent as MaleIcon } from "../../assets/maleIcon.svg";
import { ReactComponent as FemaleIcon } from "../../assets/femaleIcon.svg";
import { Avatar } from "../../components/Avatar/Avatar";
import maleUser from "../../assets/users/maleUser.svg";
import femaleUser from "../../assets/users/femaleUser.svg";
import { MembersContext } from "../../contexts/MembersContext";
import InputDate from "../../components/Form/Input/InputDate";
interface MembersFormProps {
  form: FormInstance;
  onFinish: (values: MemberCategoriesView) => void;
  user: string;
  editing: boolean;
  current?: MemberCategoriesView;
  iconColor?: string;
  icon?: string;
}

const MembersForm: React.FC<MembersFormProps> = ({
  form,
  onFinish,
  user,
  editing,
  current,
  iconColor,
  icon,
}) => {
  const { updateAvatar } = useContext(MembersContext);

  async function handleChangeImage(
    file: File,
    fileType: string
  ): Promise<void> {
    if (current?.memberId) {
      await updateAvatar(current?.memberId, file, fileType);
    }
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      className="members-form"
    >
      <FormItemHidden name="memberId" />
      <div className="members-card">
        <div className="members-card-header">
          <div className="members-card-header-img">
            <Avatar
              size={100}
              imageId={current?.fileId}
              canChangeImage={current && editing}
              defaultImage={current?.gender === 0 ? femaleUser : maleUser}
              onImageUpload={handleChangeImage}
            />
          </div>
          <div className="members-card-info">
            <div className="members-card-name">
              {editing ? (
                <Row gutter={16}>
                  <Col span={8}>
                    <FormItem
                      label="Nombre/s"
                      name="firstnames"
                      rules={[
                        { required: true, message: "Falta cargar el nombre" },
                      ]}
                    >
                      <Input placeholder="Nombre/s" />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem
                      label="Apellido/s"
                      name="lastnames"
                      rules={[
                        { required: true, message: "Falta cargar el apellido" },
                      ]}
                    >
                      <Input placeholder="Apellido/s" />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="Genero" name="gender">
                      <SelectGenre />
                    </FormItem>
                  </Col>
                </Row>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p style={{ fontSize: 20, fontWeight: 500 }}>
                    {current?.firstnames} {current?.lastnames}
                  </p>
                  {current?.gender === 0 && (
                    <FemaleIcon
                      style={{
                        color: "pink",
                        width: "1.5em",
                        height: "1.5em",
                        marginLeft: 10,
                      }}
                    />
                  )}
                  {current?.gender === 1 && (
                    <MaleIcon
                      style={{
                        color: "blue",
                        width: "1.5em",
                        height: "1.5em",
                        marginLeft: 10,
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            <Row gutter={16}>
              <Col span={4}>
                <FormItem label="Edad">
                  <Input
                    disabled={true}
                    value={
                      current?.datebirth
                        ? `${new Date().getFullYear() -
                        new Date(current.datebirth).getFullYear()
                        } `
                        : "Fecha de nacimiento no disponible"
                    }
                  />
                </FormItem>
              </Col>
              <Col span={10}>
                <FormItem label="F.Nac." name="datebirth">
                  <InputDate disabled={!editing} />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label="Documento ID"
                  name="fileId"
                  rules={[
                    { required: true, message: "Falta cargar el documento" },
                  ]}
                >
                  <Input disabled={!editing} />
                </FormItem>
              </Col>
            </Row>
          </div>
        </div>

        <Row gutter={16}>
          <Col span={16}>
            <FormItem label="Dirección" name="address" noStyle>
              <Input disabled={!editing} />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="Piso" name="floor" noStyle>
              <Input disabled={!editing} />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <FormItem label="Depto" name="apartment" noStyle>
              <Input disabled={!editing} />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="Ciudad" name="cityname">
              <Input disabled={!editing} />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="C. Postal" name="postalCode">
              <Input disabled={!editing} />
            </FormItem>
          </Col>
        </Row>

        <Divider orientation="left"> Contacto</Divider>
        <Row gutter={24}>
          <Col span={14}>
            <FormItem label="Email" name="email">
              <Input disabled={!editing} />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="Celular" name="phone">
              <Input disabled={!editing} />
            </FormItem>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default MembersForm;
