import React, {
  createContext,
  useCallback, useMemo, useState, useEffect
} from "react";
import { useAsyncCall } from "../hooks/useAsyncCall";
import { MatchView } from '../virtualclub/models/models/matches/Match.view';
import { MatchEventView } from '../virtualclub/models/models/matchesEvents/MatchesEvents.view';
import { MatchSportTeamView } from '../virtualclub/models/models/matchesSportTeams/MatchSportTeam.view';
import { useMatchTimerService } from '../services/useMatchTimerService';
import { TimerMatchStatus } from "../virtualclub/models/models/matchTimer/TimerMatchStatus.enum";
import { useTimer, TimerStatus } from './TimerContext';
import { MatchTimerState } from '../virtualclub/models/models/matchTimer/MatchTimerState';
import { MatchEventType } from '../virtualclub/models/models/matchesEvents/MatchEventType.enum';

export interface MatchTimerContextValue {
  isLoading: boolean;
  match?: MatchView;
  events?: MatchEventView[];
  cards: MatchEventView[];
  getPlayers: (teamId: string) => MatchSportTeamView[];
  addEvent: (event: MatchEventView) => void;
}

// Definición de las propiedades del componente proveedor de contexto
interface Props {
  matchId: string;
}

// Creación del contexto
export const MatchTimerContext = createContext<MatchTimerContextValue>(
  {} as MatchTimerContextValue
);

export const MatchTimerContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  matchId,
}) => {
  const {
    state,
    setState,
    getTime,
    getTotalPeriodTime,
    getTotalTimeOut,
    calculateTotalPeriodTime
  } = useTimer();
  const { getMatch, sendEvent, updateMatch, getMatchSportsTeams, getEvents } = useMatchTimerService();
  const [match, setMatch] = useState<MatchView>();
  const [events, setEvents] = useState<MatchEventView[]>([]);
  const [cards, setCards] = useState<MatchEventView[]>([]);

  const eventCards = [MatchEventType.GreenCard, MatchEventType.YellowCard, MatchEventType.RedCard];


  const [matchSportsTeams, setMatchSportsTeams] = useState<MatchSportTeamView[]>([]);

  const fetchMatch = useCallback(async () => {
    if (matchId) {
      let totalPeriodTime = 0;
      const vmatch = await getMatch(matchId);
      if (vmatch) {
        setMatch(vmatch);

        const matchstate: TimerStatus = {
          lastStatus: undefined,
          status: 'stopped',
          periods: vmatch.numberPeriods ?? 0,
          secondsPerPeriod: vmatch.periodsTime ?? 0,
          secondsPerBreakTime: vmatch.intervalTime ?? 0,
          currentPeriod: vmatch.cronoPeriod ?? 0,
          elapsedTime: vmatch.cronoElapsedTime ?? 0,
          timeOutElapsed: vmatch.cronoTimeOut ?? 0,
        };
        const telap = vmatch.cronoStart ? Math.abs(Math.floor((vmatch.cronoStart?.getTime() - new Date().getTime()) / 1000)) : 0;
        matchstate.elapsedTime = (vmatch.cronoElapsedTime ?? 0) + (vmatch.cronoStatus === TimerMatchStatus.Running ? telap : 0);
        matchstate.timeOutElapsed = (vmatch.cronoTimeOut ?? 0) + (vmatch.cronoStatus === TimerMatchStatus.Paused ? telap : 0);
        switch (vmatch.cronoStatus) {
          case TimerMatchStatus.Stopped: matchstate.status = "stopped"; break;
          case TimerMatchStatus.Running: matchstate.status = "running"; break;
          case TimerMatchStatus.Paused: matchstate.status = "pause"; break;
          case TimerMatchStatus.HalfTime: matchstate.status = "intermission"; break;
          case TimerMatchStatus.Finished: matchstate.status = "finished"; break;
        }

        setState(matchstate);
        totalPeriodTime = (matchstate.currentPeriod - 1) * matchstate.secondsPerPeriod + matchstate.elapsedTime;
      }

      const vplayers = await getMatchSportsTeams(matchId);
      setMatchSportsTeams(vplayers);

      const vevents = await getEvents(matchId);
      setEvents(vevents ?? []);

      const vcard: MatchEventView[] = [];



      vevents.map(ev => {
        if (ev.matchEventType && eventCards.includes(ev.matchEventType)) {
          const startSecondsTime = calculateTotalPeriodTime(ev?.period ?? 0, ev?.timeInSeconds ?? 0);
          const finishSecondsTime = startSecondsTime + (ev.matchEventType - 6) * 10;
          ev.startSecondsTime = startSecondsTime;
          ev.finishSecondsTime = finishSecondsTime;
          const pp = getTotalPeriodTime();
          if (finishSecondsTime > totalPeriodTime) {
            vcard.push(ev);
          }
        }
      });

      setCards(vcard);

    }
  }, [getEvents, getMatch, getMatchSportsTeams, matchId, setState]);

  const loader = useAsyncCall(fetchMatch, []);

  // useEffect(() => {


  //   if (match?.cronoStatus !== TimerStatus.Paused && match?.cronoStatus !== TimerStatus.Running) {
  //     return;
  //   }

  //   if (match?.cronoStatus === TimerStatus.Paused) {
  //     setTimeOut(prev => prev + 1);
  //   }
  //   if (match?.cronoStatus === TimerStatus.Running || match?.cronoStatus === TimerStatus.Paused) {
  //     setelapsedTime(prev => {
  //       if (prev + 1 >= durationTime) {
  //         const cronoStatus = match.cronoStatus === TimerStatus.HalfTime ? TimerStatus.Running : TimerStatus.HalfTime;
  //         const duratime = (cronoStatus === TimerStatus.HalfTime ? match?.intervalTime : match?.periodsTime) ?? 0;

  //         setDurationTime(duratime);

  //         const status: MatchTimerState = {
  //           cronoStart: new Date(),
  //           cronoStatus
  //         };

  //         updateMatch(match?.matchId!, status);
  //         setMatch((prevmatch) => ({ ...prevmatch, ...status }));

  //         if (cronoStatus === TimerStatus.Running) {
  //           resetTimer();
  //         } else if (cronoStatus === TimerStatus.HalfTime) {
  //           stopTimer();
  //         }

  //         return 0;
  //       }
  //       return prev + 1;
  //     });
  //   }
  // }, [durationTime, match, stopTimer, time]);


  const addEvent = useCallback(async (event?: MatchEventView) => {
    if (!event) return;

    let startSecondsTime = 0;
    let finishSecondsTime = 0;


    if (event.matchEventType && [MatchEventType.GreenCard].includes(event.matchEventType)) {
      startSecondsTime = getTotalPeriodTime();
      finishSecondsTime = startSecondsTime + 120;
    }

    const ev: MatchEventView = {
      ...event,
      matchId: match?.matchId,
      period: match?.cronoPeriod,
      timeInSeconds: getTime(),
      createdAt: new Date(),
      startSecondsTime,
      finishSecondsTime
    };
    if (match?.matchId) {
      await sendEvent(match?.matchId, ev);
      setEvents((prevs) => [...prevs, ev]);
    }

    if (event.matchEventType) {
      if (eventCards.includes(event.matchEventType)) {
        const startSecondsTime = calculateTotalPeriodTime(event?.period ?? 0, ev?.timeInSeconds ?? 0);
        const finishSecondsTime = startSecondsTime + (event.matchEventType - 6) * 10;
        event.startSecondsTime = startSecondsTime;
        event.finishSecondsTime = finishSecondsTime;
        setCards(prev => ([...prev, event]));

      }
      if (match && event.matchEventType === MatchEventType.Goal) {
        const update: Partial<MatchView> = {
          cronoStart: new Date(),
          homeScore: (match?.homeScore ?? 0) + (event.federationTeamId === match?.homeFederationTeamId ? 1 : 0),
          awayScore: (match?.awayScore ?? 0) + (event.federationTeamId === match?.awayFederationTeamId ? 1 : 0),
        };

        setMatch(prev => ({ ...prev, ...update }));
        updateMatch(match?.matchId!, update)

      }
    }
  }, [getTime, getTotalPeriodTime, match?.cronoPeriod, match?.cronoStart, match?.matchId, sendEvent]);

  const updatingMatch = useCallback((status: MatchTimerState) => {
    updateMatch(matchId, status);
    setMatch((prev) => ({ ...prev, ...status }));
    console.warn("STATUS", status);
  }, [matchId]);

  useEffect(() => {
    if (!state.lastStatus) {
      return;
    }
    const status: MatchTimerState = {
      cronoPeriod: state.currentPeriod,
      cronoStart: state.lastStatus,
      cronoPlaying: true,
      cronoStatus: TimerMatchStatus.Running,
      cronoElapsedTime: getTime(),
      cronoTimeOut: getTotalTimeOut()
    };
    switch (state.status) {
      case "running":
        status.cronoStatus = TimerMatchStatus.Running;
        addEvent({ matchEventType: MatchEventType.Start, details: `Inicio del periodo ${status.cronoPeriod}` });
        // addEvent({ matchEventType: MatchEventType.Event, details: `Reanuda periodo ${status.cronoPeriod}` });
        break;
      case "pause":
        status.cronoStatus = TimerMatchStatus.Paused;
        addEvent({ matchEventType: MatchEventType.Event, details: "Tiempo fuera" });
        break;
      case "intermission":
        status.cronoStatus = TimerMatchStatus.HalfTime;
        addEvent({ matchEventType: MatchEventType.Intermission, details: "Comienzo del Entretiempo" });
        break;

      case "stopped":
        status.cronoStatus = TimerMatchStatus.Stopped;
        addEvent({ matchEventType: MatchEventType.EndIntermission, details: "Fin del entretiempo" });
        break;
      case "finished":
        status.cronoStatus = TimerMatchStatus.Finished;
        addEvent({ matchEventType: MatchEventType.EndMatch, details: "Final del partido" });
        break;
    }
    updatingMatch(status);
  }, [state.currentPeriod, state.lastStatus, state.status, updatingMatch])


  // const start = useCallback(async () => {
  //   if (!match?.matchId) {
  //     return;
  //   }

  //   // const status: MatchTimerState = {
  //   //   cronoPeriod: (match?.cronoPeriod ?? 0),
  //   //   cronoStart: new Date(),
  //   //   cronoPlaying: true,
  //   //   cronoStatus: TimerStatus.Running
  //   // };

  //   // if (match?.cronoStatus === TimerStatus.Stopped) {
  //   //   status.cronoPeriod = status.cronoPeriod! + 1;
  //   //   addEvent({ matchEventType: MatchEventType.Start, details: `Inicio del periodo ${status.cronoPeriod}` });
  //   //   setDurationTime(match?.periodsTime ?? 0);
  //   // }
  //   // if (match?.cronoStatus === TimerStatus.Paused) {
  //   //   status.cronoTimeOut = (match?.cronoTimeOut ?? 0) + getElapsedTime();
  //   //   addEvent({ matchEventType: MatchEventType.Event, details: "Reinicio" });
  //   // }
  //   // await updateMatch(match?.matchId, status);

  //   // setMatch((prev) => ({ ...prev, ...status }));

  //   // startTimer();

  // }, [match?.matchId]);

  // const pause = useCallback(async () => {
  //   if (!match?.matchId) {
  //     return;
  //   }

  //   // if (match && match.cronoStatus === TimerStatus.Running) {
  //   //   addEvent({ matchEventType: MatchEventType.Event, details: "Tiempo fuera" });
  //   //   const status: MatchTimerState = {
  //   //     cronoStart: new Date(),
  //   //     cronoStatus: TimerStatus.Paused,
  //   //     cronoElapsedTime: (match.cronoElapsedTime ?? 0) + getElapsedTime()
  //   //   };
  //   //   await updateMatch(match?.matchId, status);
  //   //   setMatch((prev) => ({ ...ProgressEvent, ...status }));
  //   //   resetTimer();
  //   // }
  // }, [match]);

  // const handleTimeComplete = useCallback(() => {
  //   setMatch((prev) => {
  //     let cronoPeriod = prev?.cronoPeriod ?? 1;
  //     let cronoPlaying = prev?.cronoPlaying;
  //     let cronoStatus = prev?.cronoStatus;

  //     // if (cronoPlaying) {
  //     //   if (cronoPeriod === match?.numberPeriods) {
  //     //     cronoStatus = TimerStatus.Finish;
  //     //     addEvent({ matchEventType: MatchEventType.EndMatch, details: "Fin del partido" });
  //     //   } else {
  //     //     cronoPlaying = false;
  //     //     addEvent({ matchEventType: MatchEventType.EndPeriod, details: "Fin del periodo" });
  //     //     addEvent({ matchEventType: MatchEventType.Intermission, details: "Inicio del intervalo" });
  //     //   }
  //     // } else {
  //     //   cronoStatus = TimerStatus.Stop;
  //     //   addEvent({ matchEventType: MatchEventType.EndIntermission, details: "Fin del intervalo" });
  //     // }

  //     // setTimerState((prev) => ({
  //     //   ...prev,
  //     //   duration: cronoPlaying ? match?.periodsTime ?? 0 : match?.intervalTime ?? 0,
  //     //   elapsed: 0,
  //     //   status: cronoStatus!,
  //     // }));

  //     //await updateMatch(prev?.matchId, status);
  //     return { ...prev, cronoStatus, cronoStart: new Date(), cronoElapsedTime: 0, cronoPlaying, cronoPeriod };
  //   });
  // }, [setMatch]);

  const getPlayers = useCallback((federationTeamId: string) => {
    return matchSportsTeams.filter((x) => x.federationTeamId === federationTeamId);
  }, [matchSportsTeams]);

  // const startPeriodTimer = () => {
  //   if (timerRef.current) return; // Evitar múltiples timers

  //   timerRef.current = setInterval(() => {
  //     setCurrentTime(prev => {
  //       // Si llegamos al final del período
  //       if (prev >= PERIOD_DURATION) {
  //         clearInterval(timerRef.current);
  //         timerRef.current = null;
  //         setIsPlaying(false);
  //         setIsBreak(true);
  //         return prev;
  //       }
  //       return prev + 1;
  //     });
  //   }, 1000);
  // };

  const contextValue = useMemo<MatchTimerContextValue>(() => ({
    isLoading: loader.loading,
    match,
    events,
    cards,
    getPlayers,
    addEvent,
  }), [loader.loading, match, events, cards, getPlayers, addEvent]);

  return (
    <MatchTimerContext.Provider value={contextValue}>
      {children}
    </MatchTimerContext.Provider>
  );
};
