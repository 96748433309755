import React, { useEffect, useState } from "react";
import { Button, Select } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { SelectItem } from "./SelectItem";
import styles from "./OrderBy.module.css";

const { Option } = Select;

interface OrderByProps {
  value?: string;
  options?: SelectItem[];
  placeholder?: string;
  onChange?: (value: string) => void;
  showSort?: boolean;
  style?: React.CSSProperties;
}

export const OrderBy: React.FC<OrderByProps> = ({
  value,
  options,
  placeholder,
  onChange,
  showSort = true,
  style, // Desestructuración de la nueva propiedad
}) => {
  const [selectedOption, setSelectedOption] = useState<string>();
  const [isAscending, setIsAscending] = useState<boolean>(true);

  useEffect(() => {
    if (value) {
      const k = value.split("_");
      setSelectedOption(k[0]);
      if (k.length > 1) {
        setIsAscending(k[1] !== "DESC")
      }
    } else {
      if (options?.length) {
        setSelectedOption(options[0].value!.toString());
      }
      setIsAscending(true);
    }
  }, [options, value]);

  const handleChangeOption = (value: string) => {
    setSelectedOption(value);
    if (showSort) {
      onChange?.(`${value}_${isAscending ? "ASC" : "DESC"}`);
    }
    onChange?.(value);
  };

  const handleChangeSort = () => {
    setIsAscending(!isAscending);
    onChange?.(`${selectedOption}_${isAscending ? "DESC" : "ASC"}`); // Se invierte el isAsc porque todavia no actualizo el state
  };

  return (
    <div className={styles.selectContainer} style={style}>
      <Select
        className={styles.styledSelect}
        value={selectedOption || undefined}
        onChange={handleChangeOption}
        placeholder={
          <span className={styles.placeholder}>
            {placeholder || "Ordenar por"}
          </span>
        }
      >
        {options?.map((option) => (
          <Option key={option.value} value={option.value}>
            {` ${option.label}`}
          </Option>
        ))}
      </Select>
      {showSort && (
        <Button
          className={styles.sortButton}
          onClick={handleChangeSort}
          icon={isAscending ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
        />
      )}
    </div>
  );
};
