import React, { useState } from "react";
import { Input } from "antd";
import "./PlayerCard.css";


import { FormItem } from "../../components/Form/FormItem/FormItem";
import { SportTeamStatus } from "../../virtualclub/models/models/sportsTeams/SportTeamStatus.enum";

import Popup from "../../components/Modal/Popup";
import { ButtonSave } from "../../components/Form/Buttons/ButtonSave";
import { EnumHelper } from '../../helpers/EnumHelper';


interface properties {
  openPopup?: boolean;
  status?: SportTeamStatus;
  onChangeStatus?: (value: SportTeamStatus, reason: string) => void;
  onCancel?: () => void;
}

export const PlayerCardChangeStatus: React.FC<properties> = ({ openPopup, status, onChangeStatus, onCancel }) => {
  const [reason, setReason] = useState("");

  const handleUpdate = async () => {
    if (status) {
      onChangeStatus?.(status, reason);
    } else {
      onCancel?.();
    }
  };

  return (
    <Popup visible={openPopup} onClose={onCancel} title={`Cambiar estado a ${status ? EnumHelper.toText(SportTeamStatus, status) : ""}`}>
      <FormItem label="Razón:">
        <Input
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder="Escribe la razón aquí"
        />
      </FormItem>
      <div style={{ marginTop: 16, textAlign: "right" }}>
        <ButtonSave
          onClick={() => handleUpdate()}
        ></ButtonSave>
      </div>
    </Popup>
  );
};
