import Logo from "../../assets/VC.svg";

interface properties {
  src?: string;
  alt?: string;
  className?: string;
  style?: React.CSSProperties;
}

export const SimpleImage: React.FC<properties> = ({ src, alt, className, style }) => {

  const handleImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = Logo;
  };

  return <img
    src={src}
    alt={alt}
    onError={handleImageError}
    className={className}
    style={style}
  />;
};
