import React from "react";
import disableImage from "../../../assets/icons/buttonDisable.svg";
import "./ButtonDisable.css";

interface properties {
  text?: string;
  onClick?: () => void;
  size?: number;
}

export const ButtonDisable: React.FC<properties> = ({ onClick, size = 30, text = "Inhabilitar" }) => {
  const style: React.CSSProperties = {
    cursor: "pointer",
  };
  return (
    <img src={disableImage} onClick={onClick} style={style} alt="Inhabilitar" />
  );
};
