import React from "react";
import "./SportImage.css"
import Logo from "../../assets/VC.svg";
import { SimpleImage } from "./SimpleImage";

interface properties {
  sportId?: number;
  className?: string;
  style?: React.CSSProperties;
}

export const SportImage: React.FC<properties> = ({ sportId, className, style }) => {
  const getSportImage = (id?: number): string => {
    if (id) {
      return `/assets/sport${id}.svg`;
    }
    return Logo;
  };

  const icon = getSportImage(sportId);

  return <div className="sport-icon" style={style}>
    <SimpleImage
      src={icon}
      alt={``}
      className={className}
      style={style}
    />
  </div>

};
