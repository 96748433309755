import React, { ReactNode } from "react";
import { Select, SelectProps } from "antd";
import "./SelectExt.css";
import { DefaultOptionType } from "antd/es/select";
import { InputExt } from "./InputExt";

export interface SelectExtProps extends SelectProps {
  loading?: boolean;
  readOnly?: boolean;
  removeIcon?: boolean;
  type?: "ghost" | "full";
}

export interface SelectItem {
  value: string | number;
  label: string | ReactNode;
}
export const SelectExt: React.FC<SelectExtProps> = ({
  readOnly,
  style,
  value,
  removeIcon,
  options,
  onChange,
  disabled,
  type = "ghost",
  ...props
}) => {
  const selectClassName = `${props.className || ""} ${type === "ghost" ? "select-ghost" : "select-full"
    }`.trim();

  if (readOnly || disabled) {
    const exists = options?.find((x) => x.value === value);
    const valueText: string = exists?.label?.toString() ?? "";
    return (
      <InputExt
        value={valueText}
        style={style}
        className={selectClassName}
        readOnly
      />
    );
  }

  function handleChange(
    value: any,
    option: DefaultOptionType | DefaultOptionType[]
  ): void {
    onChange?.(value, option);
  }

  return (
    <Select
      className={selectClassName}
      options={options}
      style={{ width: "100%", ...style }}
      suffixIcon={removeIcon ? null : undefined}
      value={value}
      {...props}
      onChange={handleChange}
    />
  );
};
