import { apiRoutes } from "../constants/ApiRouteConstants";
import { apiGet, apiPatch } from "./BaseService";
import { SportTeamView } from '../virtualclub/models/models/sportsTeams/SportTeam.view';
import { SportTeamCategory } from '../virtualclub/models/models/members/MemberCategories.view';
import { SportTeamCategoryAvailableFilter, SportTeamCategoryAvailableView } from "../virtualclub/models/models/sportsTeamsCategories/SportTeamCategoryAvailableView";
import { PageResult } from '../virtualclub/models/base/PageResult';
import { BuildUrl } from '../helpers/UrlHelper';

export const useSportsTeamsCategoriesService = () => {

  const updateCategory = async (sportTeamCategoryId: string, values: SportTeamCategory): Promise<SportTeamView | undefined> => {
    try {

      const apiUrl = apiRoutes.sportTeamCategoryId.replace(":id", sportTeamCategoryId)
      const result = await apiPatch<SportTeamView, SportTeamView>(apiUrl, values);
      return result;
    } catch (er) {
      console.log(er);
    }
    return undefined;
  };

  const getAvailables = async (filters: SportTeamCategoryAvailableFilter): Promise<PageResult<SportTeamCategoryAvailableView> | undefined> => {
    try {
      const url = BuildUrl(apiRoutes.sportTeamCategoryAvailables, {}, filters);
      const result = await apiGet<PageResult<SportTeamCategoryAvailableView> | undefined>(url);
      return result;
    } catch (er) {
      console.log(er);
    }
    return undefined;
  }

  return { getAvailables, updateCategory };
}