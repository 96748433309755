import React, { useCallback, useContext, useEffect, useState } from "react";
import { Form, message, Col, Row } from "antd";
import "../SideRightCard.css";
import { FormItem } from "../../components/Form/FormItem/FormItem";
import { MembersContext } from "../../contexts/MembersContext";
import { ButtonSave } from "../../components/Form/Buttons/ButtonSave";
import { SportsTeamsCreate } from "../../virtualclub/models/models/sportsTeams/SportsTeams.create";
import { SelectFederation } from "../../components/lookups/SelectFederation";
import { FederationTeam } from "../../virtualclub/models/models/federationsTeams/FederationTeam";
import { SelectShirtNumber } from "../../components/lookups/SelectShirtNumber";
import { SelectPosition } from "../../components/lookups/SelectPosition";
import { SelectSportTeamType } from "../../components/lookups/SelectSportTeamType";
import { SelectFederatedTeams } from "../../components/lookups/SelectFederatedTeams";
import { AccountView } from "../../virtualclub/models/models/accounts/Account.view";
import { SelectCategory } from "../../components/lookups/selectCategory";
import ShirtPopup from "../../components/Modal/ShirtPopup";
import { Shirt } from "../../components/Shirt/Shirt";
import { PlayerShirt } from "../../components/Shirt/PlayerShirt";
import { MemberSportTeam } from "../../virtualclub/models/models/members/Member.sportteam";

interface properties {
  visible: boolean;
  onClose: () => void;
  sportTeam?: MemberSportTeam | null;
}

export const MembersActivityPopup: React.FC<properties> = ({
  visible,
  onClose,
  sportTeam: initialSportTeam,
}) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const { current, editing, addSportTeam } = useContext(MembersContext);

  const [sportTeam, setSportTeam] = useState<SportsTeamsCreate>(
    new SportsTeamsCreate()
  );
  const [shirtNumber, setShirtNumber] = useState<number>(0);
  const [federationTeam, setFederationTeam] = useState<FederationTeam>();

  useEffect(() => {
    if (initialSportTeam) {
      form.setFieldsValue(initialSportTeam);

      setShirtNumber(initialSportTeam.shirtNumber || 0);
    }
  }, [initialSportTeam, form]);

  const handleClose = () => {
    onClose();
  };

  const onFinish = useCallback(async (): Promise<void> => {
    try {
      const values = await form.validateFields();
      if (current?.memberId) {
        const model: SportsTeamsCreate = {
          ...values,
          memberId: current?.memberId,
        };
        const result = await addSportTeam(model);
        if (result) {
          messageApi.open({
            type: "success",
            content: "Guardado con éxito",
          });
          handleClose();
          return;
        }
      }
    } catch (err) {
      console.error("Error al guardar el equipo deportivo:", err);
    }
    messageApi.open({
      type: "error",
      content: "Error al guardar.",
    });
  }, [addSportTeam, current?.memberId, form, messageApi]);

  const handleChangeFederation = (value: AccountView, option: any): void => {
    setSportTeam(prev => ({ ...prev, federationId: value.accountId }));
    form.setFieldsValue({ federationId: value.accountId });
  };

  const handleChangeTeam = (value: FederationTeam, option: any): void => {
    setFederationTeam(value);
    form.setFieldsValue({ federationTeamId: value.federationTeamId });
  };

  const handleChangeSportTeamSport = (value: any, option: any): void => {
    setSportTeam(prev => ({ ...prev, sportTeamCategoryType: value }));
  }

  const renderShirt = () => {
    if (sportTeam.sportTeamCategoryType === 1) {
      return (
        <PlayerShirt
          gender={current?.gender ?? 0}
          number={shirtNumber}
          name={current?.lastnames}
        />
      );
    }
    return <Shirt categoryType={sportTeam.sportTeamCategoryType} />;
  };


  return (
    <ShirtPopup
      visible={visible}
      title="Agregar Afiliaciones"
      onClose={handleClose}
      renderShirt={renderShirt}
    >
      <Form
        form={form}
        layout="horizontal"
        onFinish={onFinish}
        className="sideright-card-form"
      >
        <Row gutter={16}>
          <Col span={24}>
            <FormItem label="Federacion" name="federationId">
              <SelectFederation onChange={handleChangeFederation} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Equipo" name="federationTeamId">
              <SelectFederatedTeams
                federationId={sportTeam.federationId}
                onChange={handleChangeTeam}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Tipo" name="sportTeamCategoryType">
              <SelectSportTeamType onChange={handleChangeSportTeamSport} />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem label="Categoria" name="categoryId">
              <SelectCategory
                federationId={sportTeam.federationId}
                sportId={federationTeam?.sportId}
                readOnly={
                  !sportTeam?.sportTeamCategoryType ||
                  sportTeam?.sportTeamCategoryType > 3
                }
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem label="Numero camiseta" name="shirtNumber">
              <SelectShirtNumber />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem label="Posicion" name="position">
              <SelectPosition
                readOnly={sportTeam.sportTeamCategoryType !== 1}
              />
            </FormItem>
          </Col>
        </Row>
      </Form>
      <ButtonSave onClick={onFinish} />
    </ShirtPopup>
  );
};
