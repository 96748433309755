import React, { useContext } from "react";
import { CheckMatchPlayersList } from "./CheckMatchPlayersList";
import { CheckMatchContext } from "../CheckMatchContext";

interface properties {}

export const CheckMatchPlayers: React.FC<properties> = () => {
  const { match } = useContext(CheckMatchContext);
  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "50px",
          marginTop: 15,
        }}
      >
        <div
          style={{
            flex: 0.5,
          }}
        >
          <CheckMatchPlayersList
            federationTeamId={match?.homeFederationTeamId!}
            teamName={match?.homeTeam?.team?.teamName}
          />
        </div>

        <div
          style={{
            flex: 0.5,
          }}
        >
          <CheckMatchPlayersList
            federationTeamId={match?.awayFederationTeamId!}
            teamName={match?.awayTeam?.team?.teamName}
            reverse
          />
        </div>
      </div>
    </>
  );
};
