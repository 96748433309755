import { useContext, useState } from "react";
import { GeneralContext } from "../../contexts/GeneralContext";

import { SearchInput } from "../../components/Search/SearchInput";
import { OrderBy } from "../../components/OrderBy/OrderBy";

import { MembersContext } from "../../contexts/MembersContext";
import { ButtonAdd } from "../../components/Form/Buttons/ButtonAdd";
import { TitlePage } from "../../components/Screen/TitlePage";
import { Button, Drawer } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";

interface properties {
  onSelectMember?: () => void;
}

export const MembersSearch: React.FC<properties> = ({ onSelectMember }) => {
  const context = useContext(GeneralContext);
  const { filters, create, updateFilters } = useContext(MembersContext);
  const [isDrawerVisible, setDrawerVisible] = useState(false);

  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });

  const toggleDrawer = () => {
    setDrawerVisible(!isDrawerVisible);
  };

  function handleSearch(value: string): void {
    updateFilters?.({ search: value });
  }

  function handleSort(value: string): void {
    //updateFilters?.({ sort: value });
  }

  return (
    <>
      <TitlePage title="Listado de socios">
        {isSmallScreen ? (
          <Button
            icon={<FilterOutlined />}
            onClick={toggleDrawer}
            style={{ marginBottom: 5 }}
          >
            Filtros
          </Button>
        ) : (
          <>
            <SearchInput value={filters?.search} onChange={handleSearch} />
            <OrderBy
              options={[
                { value: "apellidos, nombres", label: "Apellido y nombre" },
                { value: "codigo", label: "Codigo" },
              ]}
              onChange={handleSort}
              showSort
            />
          </>
        )}
      </TitlePage>
      <Drawer
        title="Filtros"
        placement="top"
        closable={true}
        onClose={toggleDrawer}
        visible={isDrawerVisible}
        height={200}
      >
        <div style={{ marginBottom: 20 }}>
          <SearchInput value={filters?.search} onChange={handleSearch} />
        </div>
        <OrderBy
          options={[
            { value: "apellidos, nombres", label: "Apellido y nombre" },
            { value: "codigo", label: "Codigo" },
          ]}
          onChange={handleSort}
          showSort
        />
      </Drawer>
    </>
  );
};
