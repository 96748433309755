import React, { useCallback, useContext, useState } from "react";
import { SportTeamCategoryType } from "../../../virtualclub/models/models/sportsTeamsCategories/SportTeamCategoryType.enum";
import { SelectSportTeam } from "../../../components/lookups/SelectSportTeam";
import { MatchContext } from "../../../contexts/MatchContext";
import { SelectSportTeamPopup } from "../../../components/lookups/SelectSportTeamPopup";
import { SportTeamView } from "../../../virtualclub/models/models/sportsTeams/SportTeam.view";
import { PreSelectTeamContext } from "../preSelectTeam/PreSelectTeamContext";

interface properties {
}

export const MatchStaffList: React.FC<properties> = () => {
  const { updateStaff, getStaff } = useContext(PreSelectTeamContext);
  const [sportTeamCategoryType, setSportTeamCategoryType] =
    useState<SportTeamCategoryType>();
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);

  const { match } = useContext(MatchContext);

  const handleClick = useCallback((value: SportTeamCategoryType) => {
    setSportTeamCategoryType(value);
    setIsOpenPopup(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpenPopup(false);
  }, []);

  const handleSelected = useCallback(
    async (value: SportTeamView) => {
      const result = await updateStaff({
        ...(value as SportTeamView),
        sportTeamType: sportTeamCategoryType,
      });
      if (result) {
        setIsOpenPopup(false);
      }
    },
    [sportTeamCategoryType, updateStaff]
  );

  const staffhName = useCallback((type: SportTeamCategoryType) => {
    if (getStaff.length) {
      const [value] = getStaff.filter(x => x.sportTeamType === type);
      if (value) {
        return value.sportTeamNames;
      }
    }
    return "No seleccionado";
  }, [getStaff]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <SelectSportTeam
          sportTeamCategoryType={SportTeamCategoryType.Coach}
          onClick={(v: SportTeamCategoryType) => handleClick(v)}
          value={staffhName(SportTeamCategoryType.Coach)}
          style={{ width: "90%" }}
        />
        <SelectSportTeam
          sportTeamCategoryType={SportTeamCategoryType.PhysicalTrainer}
          onClick={(v: SportTeamCategoryType) => handleClick(v)}
          value={staffhName(SportTeamCategoryType.PhysicalTrainer)}
        />
        <SelectSportTeam
          sportTeamCategoryType={SportTeamCategoryType.Assistant}
          onClick={(v: SportTeamCategoryType) => handleClick(v)}
          value={staffhName(SportTeamCategoryType.Assistant)}
        />
      </div>

      <SelectSportTeamPopup
        isOpen={isOpenPopup}
        federationId={match?.accountId}
        sportTeamCategoryType={sportTeamCategoryType}
        onClose={handleClose}
        onSelected={handleSelected}
      />
    </div>
  );
};

export default MatchStaffList;
