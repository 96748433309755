import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import "./Layout.css";
import { AuthorizationHelper } from "../helpers/AuthorizationHelper";
import { NavItem } from "../components/NavItem/NavItem";
import { Avatar } from "../components/Avatar/Avatar";
import { routes } from "../constants/RouteConstants";
import { AccountType } from "../virtualclub/models/models/accounts/AccountType.enum";

export const SideMenu: React.FC = () => {
  const [auth] = useState(new AuthorizationHelper());
  const location = useLocation();

  const isActive = (basePath: string) => location.pathname.startsWith(basePath);

  return (
    <aside className="aside">
      <div className="aside-container">
        <div className="avatar">
          <Avatar imageId={auth.get()?.accountId} handleError={false} />
        </div>
        <div className="club-text">
          <h2 className="club-name">{auth.get()?.username}</h2>
        </div>
        <nav className="nav">
          <NavItem
            caption="Fixture"
            icon="calendar"
            to={routes.Matches}
            active={isActive(routes.Matches)}
          />
          <NavItem
            caption="Plantel"
            icon="screen-users"
            to={routes.TechnicalStaff}
            active={isActive(routes.TechnicalStaff)}
          />
          <NavItem
            caption="Jugadores"
            icon="users"
            to={routes.Players}
            active={isActive(routes.Players)}
          />
          {auth.isAccountType(AccountType.Club) && (
            <>
              <NavItem
                caption="Tactica"
                icon="user-group"
                to={routes.TeamsTactics}
                active={isActive(routes.TeamsTactics)}
              />
              <NavItem
                caption="Socios"
                icon="people-arrows"
                to={routes.Members}
                active={isActive(routes.Members)}
              />
              <NavItem
                caption="Equipos"
                icon="shield"
                to={routes.Teams}
                active={isActive(routes.Teams)}
              />
            </>
          )}

          {auth.isAccountType(AccountType.Federation) && (
            <>
              <NavItem
                caption="Federados"
                icon="shield-check"
                to={routes.FederationTeams}
                active={isActive(routes.FederationTeams)}
              />
              <NavItem
                caption="Categorias"
                icon="layer-group"
                to={routes.Categories}
                active={isActive(routes.Categories)}
              />
              <NavItem
                caption="Torneos"
                icon="trophy"
                to={routes.Tournaments}
                active={isActive(routes.Tournaments)}
              />
            </>
          )}
          <NavItem
            caption="Mi cuenta"
            icon="id-card"
            to={routes.MyAccount}
            active={isActive(routes.MyAccount)}
          />
        </nav>
      </div>
    </aside>
  );
};
