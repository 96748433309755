import { useState } from 'react';

import { apiGet, apiPost } from './BaseService';
import { apiRoutes } from '../constants/ApiRouteConstants';
import { MatchView } from '../virtualclub/models/models/matches/Match.view';
import { MatchSportTeamView } from '../virtualclub/models/models/matchesSportTeams/MatchSportTeam.view';
import { BuildUrl } from '../helpers/UrlHelper';
import { MatchEventView } from '../virtualclub/models/models/matchesEvents/MatchesEvents.view';
import { UuidHelper } from "../helpers/UuidHelper";

export const useMatchTimerService = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);


  const getMatch = async (matchId: string): Promise<MatchView | undefined> => {
    try {
      const model = await apiGet<MatchView>(apiRoutes.matchTimer.replace(':matchId', matchId));
      return model ? MatchView.fromPlain(model) : undefined;
    } catch (er) { }
    return undefined;
  };

  const getMatchSportsTeams = async (matchId: string): Promise<MatchSportTeamView[]> => {
    try {
      const urlplayers = BuildUrl(apiRoutes.matchTimerSportTeam, { matchId });
      const result = await apiGet<MatchSportTeamView[]>(urlplayers);
      return result ?? [];
    } catch (er) { }
    return [];
  };

  const getEvents = async (matchId: string): Promise<MatchEventView[]> => {
    try {
      const urlevents = BuildUrl(apiRoutes.matchTimerEvents, { matchId });
      const evs = await apiGet<MatchEventView[]>(urlevents);


      return evs ?? [];
    } catch (er) { }
    return [];
  };

  const updateMatch = async (matchId: string, values: Partial<MatchView>): Promise<void> => {
    try {
      const param = { matchId };
      const url = BuildUrl(apiRoutes.matchTimer, param)
      const model = await apiPost<MatchEventView, boolean>(url, values);
    } catch (er: any) {
      console.error(er.message);
    }
  };

  const sendEvent = async (matchId: string, event: MatchEventView): Promise<MatchEventView | undefined> => {

    const matchEventId = UuidHelper.CreateString();
    let addevent = { matchEventId, ...event };
    try {
      const param = { matchId };
      const url = BuildUrl(apiRoutes.matchTimerEvents, param)
      const model = await apiPost<MatchEventView, boolean>(url, addevent);
      if (model) {
        return addevent;
      }
    } catch (er: any) {
      console.error(er.message);
    }
    return undefined;

  };



  return { isLoading, error, getMatch, updateMatch, getEvents, sendEvent, getMatchSportsTeams };
}