import React from "react";
import { Form, Row, Col } from "antd";
import { FormInstance } from "antd/es/form";
import { Category } from "../../virtualclub/models/models/categories/Category";
import { ButtonSaveCancel } from "../../components/Form/Buttons/ButtonSaveCancel";
import { FormItem } from "../../components/Form/FormItem/FormItem";
import { FormItemHidden } from "../../components/Form/FormItem/FormItemHidden";
import Input from "../../components/Form/Input/Input";
import { SelectSport } from "../../components/lookups/SelectSport";

interface CategoryFormProps {
  form: FormInstance;
  editing: boolean;
  current?: Category;
  onFinish: (values: Category) => void;
  onSaveClick?: () => void;
  onCancelClick?: () => void;
}

const CategoryForm: React.FC<CategoryFormProps> = ({
  form,
  editing,
  onFinish,
  onSaveClick,
  onCancelClick,
}) => {
  return (
    <Form
      form={form}
      initialValues={new Category()}
      layout="vertical"
      onFinish={onFinish}
      disabled={!editing}
    >
      <FormItemHidden name="categoryId" />

      <Row gutter={16}>
        <Col span={24}>
          <FormItem label="Nombre de Categoria" name="categoryName" noStyle>
            <Input
              style={{ fontSize: 20 }}
              placeholder="Categoria"
              disabled={!editing}
            />
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem
            label="Deporte"
            name="sportId"
            rules={[{ required: true, message: "Ingrese el deporte" }]}
          >
            <SelectSport disabled={!editing} />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: 10 }}>
        <Col span={6}>
          <FormItem
            label="Código"
            name="categoryCode"
            rules={[{ required: true, message: "Ingrese el código" }]}
          >
            <Input disabled={!editing} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="Nombre Corto"
            name="categoryShort"
            rules={[{ required: true, message: "Ingrese el nombre corto" }]}
          >
            <Input disabled={!editing} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem label="Orden" name="categoryOrder">
            <Input disabled={!editing} />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={4}>
          <FormItem label="Juez" name="judges">
            <Input min={0} disabled={!editing} />
          </FormItem>
        </Col>
        <Col span={4}>
          <FormItem label="DT" name="technicalDirector">
            <Input min={0} disabled={!editing} />
          </FormItem>
        </Col>
        <Col span={4}>
          <FormItem label="PF" name="physicalTrainer">
            <Input min={0} disabled={!editing} />
          </FormItem>
        </Col>
        <Col span={4}>
          <FormItem label="Asistente" name="assistants">
            <Input min={0} disabled={!editing} />
          </FormItem>
        </Col>
        <Col span={4}>
          <FormItem label="Arbitro" name="referees">
            <Input min={0} disabled={!editing} />
          </FormItem>
        </Col>
      </Row>

      {editing && (
        <ButtonSaveCancel
          onSaveClick={onSaveClick}
          onCancelClick={onCancelClick}
        />
      )}
    </Form>
  );
};

export default CategoryForm;
