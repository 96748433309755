import { useContext, useState } from "react";
import { SearchInput } from "../../components/Search/SearchInput";
import { OrderBy } from "../../components/OrderBy/OrderBy";
import { TitlePage } from "../../components/Screen/TitlePage";
import { Button, Checkbox, Drawer } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { SelectPosition } from "../../components/lookups/SelectPosition";
import { SportsTeamsContext } from "../../contexts/SportsTeamsContext";
import { SelectGenre } from "../../components/lookups/SelectGenre";

interface properties {
  onSelectMember?: () => void;
}

export const TechnicalStaffSearch: React.FC<properties> = ({
  onSelectMember,
}) => {
  const { filters, updateFilters } = useContext(SportsTeamsContext);
  const [isDrawerVisible, setDrawerVisible] = useState(false);

  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });

  const toggleDrawer = () => {
    setDrawerVisible(!isDrawerVisible);
  };

  function handleSearch(value: string): void {
    updateFilters?.({ search: value });
  }

  function handleSort(value: string): void {
    //updateFilters?.({ sort: value });
  }

  function handlePositionChange(position: number | undefined): void {
    updateFilters?.({ position });
  }
  function handleStatusChange(e: boolean): void {
    updateFilters?.({ sportTeamStatus: e ? 1 : undefined });
  }
  function handleGenreChange(gender: number | undefined): void {
    updateFilters?.({ gender });
  }

  return (
    <>
      <TitlePage title="Listado de Plantel Tecnico">
        {isSmallScreen ? (
          <Button
            icon={<FilterOutlined />}
            onClick={toggleDrawer}
            style={{ marginBottom: 5 }}
          >
            Filtros
          </Button>
        ) : (
          <>
            <SearchInput value={filters?.search} onChange={handleSearch} />

            <SelectGenre
              value={filters?.gender}
              onChange={handleGenreChange}
            />
            <SelectPosition
              isFilter
              value={filters?.position}
              onChange={handlePositionChange}
            />

            <Checkbox
              checked={filters?.sportTeamStatus === 1}
              onChange={(e) => handleStatusChange(e.target.checked)}
            >
              Pendientes
            </Checkbox>
          </>
        )}
      </TitlePage>
      <Drawer
        title="Filtros"
        placement="top"
        closable={true}
        onClose={toggleDrawer}
        visible={isDrawerVisible}
        height={200}
      >
        <div style={{ marginBottom: 20 }}>
          <SearchInput value={filters?.search} onChange={handleSearch} />
        </div>
        <OrderBy
          options={[
            { value: "apellidos, nombres", label: "Apellido y nombre" },
            { value: "codigo", label: "Codigo" },
          ]}
          onChange={handleSort}
          showSort
        />
      </Drawer>
    </>
  );
};
