import React, { useContext, useMemo } from "react";
import { CheckMatchPlayer } from "./CheckMatchPlayer";
import { SportTeamCategoryType } from "../../../../virtualclub/models/models/sportsTeamsCategories/SportTeamCategoryType.enum";
import { CheckMatchContext } from "../CheckMatchContext";
interface properties {
  federationTeamId: string;
  reverse?: boolean;
  teamName?: string;
}

export const CheckMatchPlayersList: React.FC<properties> = ({
  federationTeamId,
  reverse = false,
  teamName,
}) => {
  const { getMatchSportTeams, match } = useContext(CheckMatchContext);

  const players = useMemo(() => {
    return getMatchSportTeams
      ? getMatchSportTeams(federationTeamId, SportTeamCategoryType.Player)
      : [];
  }, [federationTeamId, getMatchSportTeams]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <span style={{ fontWeight: 700, fontSize: 18 }}>
          {teamName} Seleccionados
        </span>
        <span className="info-text">
          Anotados {players.length} |
          <span style={{ fontWeight: 700, fontSize: 18, color: "#0100B4" }}>
            Act 0
          </span>
        </span>
      </div>
      {players.map((j) => (
        <CheckMatchPlayer
          key={j.matchSportTeamId}
          value={j}
          reverse={reverse}
        />
      ))}
    </div>
  );
};
