import { useContext, useMemo } from "react";
import "./CardsList.css";
import { MatchTimerContext } from "../../../contexts/MatchTimerContext";
import { NumberHelper } from "../../../helpers/NumberHelper";
import { Icon } from "../../../components/Icons/Icon";
import { useTimer } from '../../../contexts/TimerContext';
import { Card } from './Card';

export const CardsList = () => {
  const { match, cards } = useContext(MatchTimerContext);
  const {
    getTotalPeriodTime,
  } = useTimer();

  const homeCards = useMemo(() => {
    return cards.filter((ev) => ev.federationTeamId === match?.homeFederationTeamId);
  }, [cards, match?.homeFederationTeamId]);

  const awayCards = useMemo(() => {
    return cards.filter((ev) => ev.federationTeamId === match?.awayFederationTeamId);
  }, [cards, match?.awayFederationTeamId]);


  return (
    <div style={{ marginTop: 15 }}>
      <span className="crono-tarjetas-text">Tarjetas</span>
      <div className="crono-tarjetas">
        <div className="tarjetas-columna">
          {homeCards?.map((card, index) => (
            <Card event={card} type="home" />
          ))}
        </div>
        <div className="tarjetas-columna">
          {awayCards?.map((card, index) => (
            <Card event={card} type="away" />
          ))}
        </div>
      </div>
    </div>
  );
};
