import React from "react";
import heroImage from "./assets/Hero2Image.png";

const Hero2Section: React.FC = () => {
  return (
    <div className="hero-section">
      <div className="hero-text">
        <h1>
          <strong>ORGANIZA Y GESTIONA</strong> torneos deportivos sin
          complicaciones
        </h1>
        <button className="btn white-btn">Comenzar Ya</button>
        <button className="btn green-btn">Más Info</button>
      </div>
    </div>
  );
};

export default Hero2Section;
