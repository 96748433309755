export class EnumHelper {
    /**
     * Convierte un valor de un enum (número o string) a su nombre textual.
     * @param enumType El enum al que pertenece el valor.
     * @param value El valor (número o string) a convertir.
     * @returns El nombre textual del enum o undefined si no existe.
     */
    static toText<T extends object>(enumType: T, value: number | string): string | undefined {
        const key = Object.keys(enumType).find(
            (k) => (enumType as any)[k] === value
        );
        return key;
    }

    /**
     * Convierte un texto de un enum a su valor (número o string).
     * @param enumType El enum al que pertenece el texto.
     * @param text El texto del enum.
     * @returns El valor del enum (número o string) o undefined si no existe.
     */
    static toValue<T extends object>(enumType: T, text: string): number | string | undefined {
        return (enumType as any)[text] ?? undefined;
    }
}
