import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Form, message, Row } from 'antd';
import { ButtonSaveCancel } from '../../components/Form/Buttons/ButtonSaveCancel';
import Card from '../../components/Card/Card';
import Label from '../../components/Form/Label/Label';
import { FormItem } from '../../components/Form/FormItem/FormItem';
import { FormItemHidden } from '../../components/Form/FormItem/FormItemHidden';
import { TournamentsContext } from '../../contexts/TournamentsContext';
import Input from '../../components/Form/Input/Input';
import { TournamentView } from '../../virtualclub/models/models/tournaments/Tournament.view';
import { SelectCategory } from '../../components/lookups/selectCategory';
import { Category } from '../../virtualclub/models/models/categories/Category';
import { SelectGenre } from '../../components/lookups/SelectGenre';
import { SelectTournamentType } from '../../components/lookups/SelectTournamentType';
import { TournamentsTeams } from './TournamentsTeams';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/RouteConstants';
import { ButtonAction } from '../../components/Form/Buttons/ButtonAction';
import { Icon } from '../../components/Icons/Icon';

const TourmanentCard: React.FC = () => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { current, edit, cancel, editing, update } =
    useContext(TournamentsContext);
  const [category, setCategory] = useState<Category>();
  const navigate = useNavigate();

  useEffect(() => {
    form.setFieldsValue(current ?? {});
  }, [current, form]);

  const handleEditClick = () => {
    edit();
  };
  const handleViewClick = () => {
    navigate(routes.Tournament.replace(':id', current?.tournamentId ?? ''));
  };
  const handleSaveClick = useCallback((): void => {
    form.submit();
  }, [form]);

  const handleCancelClick = () => {
    form.setFieldsValue(current ?? {});
    cancel();
  };
  function handleChangeCategory(value: any, option: any): void {
    form.setFieldValue('categoryId', value.categoryId);
    setCategory(value);
  }

  async function onFinish(values: any): Promise<void> {
    try {
      const result = (await update(values)) ?? false;
      if (result) {
        console.log(1);
        messageApi.open({
          type: 'success',
          content: 'Guardado con exito',
        });
        return;
      }
    } catch (err) {
      console.log(err);
    }
    messageApi.open({
      type: 'error',
      content: 'Error al guardado.',
    });
  }
  return (
    <Card>
      {contextHolder}
      <Form
        form={form}
        initialValues={new TournamentView()}
        layout='vertical'
        onFinish={onFinish}
        disabled={!editing}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            height: 30,
            marginTop: 5,
            gap: 20,
          }}
        >
          {!editing && <ButtonAction type='edit' onClick={handleEditClick} />}
          {editing && (
            <ButtonSaveCancel
              onSaveClick={handleSaveClick}
              onCancelClick={handleCancelClick}
            />
          )}
          <button onClick={handleViewClick}>
            <i className='fa-solid fa-search' />
          </button>
        </div>
        <FormItemHidden name='tournamentId' />
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <Label fontSize='14' fontWeight='500' gray>
            Cod. {current?.tournamentCode ?? 0}
          </Label>
        </div>
        {editing ? (
          <Row gutter={24}>
            <Col span={24}>
              <FormItem label='Nombre del Torneo' name='tournamentName' noStyle>
                <Input
                  style={{ fontSize: 20 }}
                  disabled={!editing}
                  placeholder='Torneo'
                />
              </FormItem>
            </Col>
          </Row>
        ) : (
          <Label fontSize='20' fontWeight='500'>
            {current?.tournamentName}
          </Label>
        )}

        <Row gutter={24} style={{ marginTop: 10 }}>
          <Col span={24}>
            <FormItem label='Categoria' name='categoryId'>
              <SelectCategory onChange={handleChangeCategory} />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <FormItem
              label='Género'
              name='gender'
              rules={[{ required: true, message: 'Seleccione el género' }]}
            >
              <SelectGenre />
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem
              label='Tipo de Torneo'
              name='tournamentType'
              rules={[
                { required: true, message: 'Seleccione el tipo de torneo' },
              ]}
            >
              <SelectTournamentType />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <FormItem
              label='Fecha de Registro'
              name='registrationDate'
              rules={[
                { required: true, message: 'Ingrese la fecha de registro' },
              ]}
            >
              <Input type='date' disabled={!editing} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label='Fecha de Cierre de Registro'
              name='closingRegistrationDate'
              rules={[
                {
                  required: true,
                  message: 'Ingrese la fecha de cierre de registro',
                },
              ]}
            >
              <Input type='date' disabled={!editing} />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <FormItem
              label='Fecha de Inicio del Torneo'
              name='tournamentStartDate'
              rules={[
                {
                  required: true,
                  message: 'Ingrese la fecha de inicio del torneo',
                },
              ]}
            >
              <Input type='date' disabled={!editing} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label='Fecha Final del Torneo'
              name='tournamentFinalDate'
              rules={[
                {
                  required: true,
                  message: 'Ingrese la fecha final del torneo',
                },
              ]}
            >
              <Input type='date' disabled={!editing} />
            </FormItem>
          </Col>
        </Row>
      </Form>
      <TournamentsTeams />
    </Card>
  );
};

export default TourmanentCard;
