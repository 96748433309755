import React, { useContext, useState } from "react";
import { Button, Col, Input, Row } from "antd";
import "./PlayerCard.css";
import { SportsTeamsContext } from "../../contexts/SportsTeamsContext";

import { TShirt } from "../../components/TShirt/tshirt";

import Label from "../../components/Form/Label/Label";
import Card from "../../components/Card/Card";
import { FormItem } from "../../components/Form/FormItem/FormItem";
import { FieldGame } from "../../components/FieldGame/FieldGame";
import { SportTeamStatus } from "../../virtualclub/models/models/sportsTeams/SportTeamStatus.enum";
import { AvatarImage } from "../../components/Images/AvatarImage";
import { NamesHelper } from "../../helpers/NamesHelper";
import { ButtonRemove } from "../../components/Form/Buttons/ButtonRemove";
import { AuthorizationHelper } from "../../helpers/AuthorizationHelper";
import { AccountType } from "../../virtualclub/models/models/accounts/AccountType.enum";
import { DateHelper } from "../../helpers/DateHelper";
import { SportImage } from "../../components/Images/SportImage";
import { StatusIcon } from "../../components/Icons/StatusIcon";
import { ButtonAction } from "../../components/Form/Buttons/ButtonAction";

import { Icon } from "../../components/Icons/Icon";
import { ButtonDisable } from "../../components/Form/Buttons/ButtonDisable";

import { PlayerCardChangeStatus } from "./PlayerCardChangeStatus";

export const PlayerCard: React.FC = () => {
  const [auth] = useState(new AuthorizationHelper());

  const { current, updateStatus } = useContext(SportsTeamsContext);
  const [sportTeamStatus, setSportTeamStatus] = useState<SportTeamStatus>();

  const openPopupChangeStatus = async (status: SportTeamStatus) => {
    setSportTeamStatus(status);
  };

  const handleUpdate = async (status: SportTeamStatus, reason: string) => {
    const success = await updateStatus?.(status, reason);
    if (success) {
      setSportTeamStatus(undefined);
      // TODO: poner confirmacion
    } else {
      // TODO: poner alerta que no se pudo cambiar
    }
  };

  const getGenderIcon = () => {
    switch (current?.gender) {
      case 1:
        return <Icon name="mars" color="blue" />;
      case 0:
        return <Icon name="venus" color="pink" />;
      default:
        return null;
    }
  };

  return (
    <Card>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          height: 48,
          alignItems: "center",
          gap: 8,
        }}
      >
        {current?.sportTeamCategoryStatus === SportTeamStatus.Disabled && (
          <Button onClick={() => openPopupChangeStatus(SportTeamStatus.Active)}>
            <Icon name="play" /> Habilitar
          </Button>
        )}
        {current?.sportTeamCategoryStatus === SportTeamStatus.Active && (
          <Button
            onClick={() => openPopupChangeStatus(SportTeamStatus.Disabled)}
          >
            <Icon name="clock" /> Inhabilitar
          </Button>
        )}
        {current?.sportTeamCategoryStatus !== SportTeamStatus.Canceled && (
          <ButtonDisable
            onClick={() => openPopupChangeStatus(SportTeamStatus.Canceled)}
          />
        )}
      </div>

      <PlayerCardChangeStatus
        openPopup={!!sportTeamStatus}
        status={sportTeamStatus}
        onChangeStatus={handleUpdate}
        onCancel={() => setSportTeamStatus(undefined)}
      />

      <div className="player-card">
        <div>
          <AvatarImage id={current?.teamId} style={{ width: "60px" }} />
        </div>

        <SportImage sportId={current?.sportId} />

        <Label fontSize="20">
          {current?.team?.teamName}{" "}
          {current?.subTeam
            ? `"${NamesHelper.LetterName(current?.subTeam)}"`
            : ""}
        </Label>
      </div>

      <div className="player-card">
        <div className="player-card-shirt">
          <TShirt
            gender={current?.gender}
            shirtNumber={current?.shirtNumber}
            name={current?.lastnames}
            backcolor={current?.team?.color}
            color={current?.team?.color1}
            fontcolor={current?.team?.color2}
          />
        </div>

        <div>
          <div className="player-card-header">
            <Label
              style={{
                width: "200px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              fontSize="20"
              fontWeight="500"
            >
              {current?.firstnames} {current?.lastnames}
            </Label>
            {getGenderIcon()}
          </div>
          <Row gutter={16}>
            <Col span={4}>
              <FormItem label="Edad">
                <Input
                  value={
                    current?.datebirth
                      ? `${
                          new Date().getFullYear() -
                          new Date(current.datebirth).getFullYear()
                        } `
                      : "Fecha de nacimiento no disponible"
                  }
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Nacimiento">
                <Input value={DateHelper.getDateString(current?.datebirth)} />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Documento">
                <Input value={current?.fileId} />
              </FormItem>
            </Col>
          </Row>
        </div>
      </div>

      <div className="player-card">
        <div className="player-card-field">
          <FieldGame
            sportId={1}
            width={120}
            height={220}
            viewPoint="Circle"
            players={[
              {
                position: current?.position ?? -1,
                number: current?.shirtNumber?.toString(),
                color: current?.team?.color ?? "white",
              },
            ]}
          />
        </div>

        <div className="player-card-stats">
          <div className="player-card-stat">
            <Icon name="futbol" color="#888" />
            <span className="player-card-stat-value">0</span>
          </div>
          <div className="player-card-stat">
            <Icon name="check-slot" color="#888" />
            <span className="player-card-stat-value">0</span>
          </div>
          <div className="player-card-stat">
            <Icon name="triangle" />
            <span className="player-card-stat-value">0</span>
          </div>
          <div className="player-card-stat">
            <Icon name="circle" />
            <span className="player-card-stat-value">0</span>
          </div>
          <div className="player-card-stat">
            <Icon name="square" />
            <span className="player-card-stat-value">0</span>
          </div>
        </div>
      </div>

      <div className="player-card ">
        <div
          style={{
            display: "flex",
            flexDirection: "row",

            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <AvatarImage id={current?.federationId} style={{ width: "50px" }} />
          <Label
            fontSize="20"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {current?.federationTeamView?.federation?.accountName}
          </Label>

          <StatusIcon
            value={current?.sportTeamCategoryStatus as number}
            size="50"
          />
        </div>
      </div>
      {current?.sportTeamCategoryStatus === SportTeamStatus.Pending && (
        <div style={{ display: "flex" }}>
          {!auth.isAccountType(AccountType.Club) && (
            <>
              <ButtonAction
                type="check"
                onClick={() => handleUpdate(SportTeamStatus.Active, "")}
              />
              <ButtonRemove
                onConfirm={() =>
                  openPopupChangeStatus(SportTeamStatus.Canceled)
                }
                itemName={current.federationTeamView?.federation?.accountName}
              />
            </>
          )}

          {auth.isAccountType(AccountType.Club) && (
            <ButtonRemove
              onConfirm={() => openPopupChangeStatus(SportTeamStatus.Canceled)}
              itemName={current.federationTeamView?.federation?.accountName}
            />
          )}
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",

          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {current?.sportTeamCategoryStatus === SportTeamStatus.Disabled && (
          <span>Inhabilitado por:{current.sportTeamStatus}</span>
        )}
      </div>
    </Card>
  );
};
