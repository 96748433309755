
export class DateHelper {

  static parse(date?: Date | string): Date {
    if (!date) return new Date();
    if (date instanceof Date) {
      return date;
    }
    return new Date(date);
  }


  static DateToKey(value: Date | string): string {
    try {
      let date: Date;
      if (value instanceof Date) date = value as Date;
      else date = new Date(value);

      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    } catch (err) {
      console.log(err);
    }
    return "";
  }

  static calculateAge(birthDateInput: string | Date): number {
    const birthDate = new Date(birthDateInput);

    // Validate that birthDate is a valid date
    if (isNaN(birthDate.getTime())) {
      throw new Error("Invalid date");
    }

    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();

    // If the person hasn't had their birthday yet this year, subtract 1
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  static compare(date1?: Date, date2?: Date): boolean {
    if (!date1 || !date2) return false;
    return this.DateToKey(date1) === this.DateToKey(date2);
  }


  static validDate(value: Date | string | undefined): Date | undefined {
    try {
      if (!value) return;

      let date: Date;

      // Si el valor es una fecha ya en tipo Date
      if (value instanceof Date) {
        date = value;
      }
      // Si es un string
      else {
        // Verifica si es un formato ISO
        if (!isNaN(Date.parse(value))) {
          date = new Date(value);
        }
        // Si es un formato 'DD/MM/YYYY HH:MM'
        else if (/\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}/.test(value)) {
          const [dayPart, timePart] = value.split(" ");
          const [day, month, year] = dayPart.split("/").map(Number);
          const [hours, minutes, seconds] = timePart.split(":").map(Number);
          date = new Date(year, month - 1, day, hours, minutes, seconds);
        }
        // Si no coincide con ningún formato esperado
        else {
          throw new Error("Formato de fecha no reconocido");
        }
      }
      return date;
    }
    catch (err: any) {
      console.log("Error al formatear la fecha:", err.message);
      return undefined;
    }
  }



  static formatTime(value: Date | string | undefined): string {
    if (!value) return "";
    const date = this.validDate(value)!;

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  static formatTimeLong(value: Date | string | undefined): string {
    if (!value) return "";
    const date = this.validDate(value)!;

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }

  static getHour(value: Date | string): string {
    let date: Date;
    if (value instanceof Date) date = value as Date;
    else date = new Date(value);
    return String(date.getHours()).padStart(2, "0");
  }
  static getMinute(value: Date | string): string {
    let date: Date;
    if (value instanceof Date) date = value as Date;
    else date = new Date(value);
    return String(date.getMinutes()).padStart(2, "0");
  }

  static setAddTime(time: string, add: number): string {
    let h = parseInt(time.substring(0, 2));
    let m = parseInt(time.substring(3, 5)) + add;

    const ex = Math.floor(m / 60);
    if (ex >= 1) {
      h += ex;
      m -= ex * 60;
    }
    return `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}`;
  }

  static betweenHours(time: string, time1?: string, time2?: string) {
    if (!time1 || !time2) return false;
    const horaDate = new Date("2000-01-01T" + time);
    const inicioDate = new Date("2000-01-01T" + time1);
    const finDate = new Date("2000-01-01T" + time2);

    // Verificar si la hora dada está entre el inicio y el fin
    return horaDate >= inicioDate && horaDate <= finDate;
  }

  static getDateString(date?: Date | string): string {
    if (!date) return "";
    let dateaux: Date;
    if (date instanceof Date) dateaux = date;
    else dateaux = new Date(date);

    var dia = dateaux.getDate().toString().padStart(2, "0");
    var mes = (dateaux.getMonth() + 1).toString().padStart(2, "0");
    var año = dateaux.getFullYear().toString().padStart(2, "0");
    return dia + "/" + mes + "/" + año;
  }

  static getDateLongString(fecha?: Date): string {
    if (!fecha) return "";
    var dia = fecha.getDate().toString().padStart(2, "0");
    var mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
    var año = fecha.getFullYear().toString().padStart(2, "0");
    const horas = String(fecha.getHours()).padStart(2, "0");
    const minutos = String(fecha.getMinutes()).padStart(2, "0");
    return `${dia}/${mes}/${año} ${horas}:${minutos}`;
  }

  static getDayNumber(date?: Date | string): number {
    if (!date) return -1;
    let dateaux: Date;
    if (date instanceof Date) dateaux = date;
    else dateaux = new Date(date);
    return dateaux.getDate();
  }

  static getMonthNumber(date?: Date | string): number {
    if (!date) return -1;
    let dateaux: Date;
    if (date instanceof Date) dateaux = date;
    else dateaux = new Date(date);
    const month = dateaux.getMonth();
    return month + 1;
  }

  static getDayName(date?: Date): string {
    if (!date) return "Invalid Date";
    const diasSemana = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    const numeroDia = date.getDay();
    return diasSemana[numeroDia];
  }

  static getDayNameShort(date?: Date): string {
    if (!date) return "Invalid Date";
    const diasSemana = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    const numeroDia = date.getDay();
    return diasSemana[numeroDia];
  }

  static getMonthName(date?: Date | number): string {
    if (!date) return "";
    const meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    let numeroMes = 0;
    if (date instanceof Date) numeroMes = date.getMonth();
    else if (typeof date === "string") numeroMes = new Date(date).getMonth();
    else numeroMes = date - 1;
    return meses[numeroMes];
  }

  static getMonthNameShort(date?: Date | number): string {
    if (!date) return "";
    const meses = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    let numeroMes = 0;
    if (date instanceof Date) numeroMes = date.getMonth();
    else numeroMes = date - 1;
    return meses[numeroMes];
  }
}
