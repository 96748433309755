import React, { useCallback, useContext, useEffect } from "react";
import { Col, Form, message, Row } from "antd";
import "../SideRightCard.css";
import { Avatar } from "../../components/Avatar/Avatar";
import Input from "../../components/Form/Input/Input";
import { FormItem } from "../../components/Form/FormItem/FormItem";
import { SelectSport } from "../../components/lookups/SelectSport";
import { FormItemHidden } from "../../components/Form/FormItem/FormItemHidden";
import Card from "../../components/Card/Card";
import { FederationTeamsContext } from "../../contexts/FederationTeamsContext";
import { FederationTeam } from "../../virtualclub/models/models/federationsTeams/FederationTeam";
import { FederationTeamAccount } from "./FederationTeamAccount";
import { NamesHelper } from "../../helpers/NamesHelper";

export const FederationTeamCard: React.FC = () => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { current, editing, edit, cancel } = useContext(FederationTeamsContext);

  useEffect(() => {
    form.setFieldsValue(current);
  }, [current, form]);

  const handleEditClick = () => {
    edit();
  };

  const handleCancelClick = () => {
    form.setFieldsValue(current ?? {});
    cancel();
  };

  const handleSaveClick = useCallback((): void => {
    form.submit();
  }, [form]);

  return (
    <Card>
      {contextHolder}
      <Form
        form={form}
        initialValues={new FederationTeam()}
        layout="vertical"
        disabled={!editing}
      >
        <div className="sideright-card-avatar" style={{ marginTop: 40 }}>
          <Avatar imageId={current?.teamId} />
        </div>

        <FormItemHidden name="teamId" />

        <Row gutter={16}>
          <Col span={20}>
            <FormItem label="Nombre" name="teamName">
              <Input disabled={!editing} />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem label="Sub-Team" >
              <Input value={NamesHelper.LetterName(current?.subTeam)} disabled={!editing} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Nombre Corto" name="teamShort">
              <Input disabled={!editing} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Deporte" name="sportId">
              <SelectSport readOnly disabled />
            </FormItem>
          </Col>
        </Row>
      </Form>
      <FederationTeamAccount />
    </Card>
  );
};
