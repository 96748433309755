import "./CardsList.css";
import { NumberHelper } from "../../../helpers/NumberHelper";
import { Icon } from "../../../components/Icons/Icon";
import { useTimer } from '../../../contexts/TimerContext';
import { MatchEventView } from '../../../virtualclub/models/models/matchesEvents/MatchesEvents.view';
import { MatchEventType } from '../../../virtualclub/models/models/matchesEvents/MatchEventType.enum';
import { useMemo } from "react";


interface properties {
  event: MatchEventView;
  type: "home" | "away";
}

export const Card: React.FC<properties> = ({ event, type }) => {
  const {
    clock,
    getTotalPeriodTime,
  } = useTimer();

  const getEventIcon = (eventType: MatchEventType) => {
    switch (eventType) {
      case MatchEventType.Goal:
      case MatchEventType.OwnGoal:
      case MatchEventType.PenaltyGoal:
      case MatchEventType.RemoveGoal:
        return <Icon name="futbol" />;
      case MatchEventType.GreenCard:
        return <Icon name="triangle" />;
      case MatchEventType.YellowCard:
        return <Icon name="circle" />;
      case MatchEventType.RedCard:
        return <Icon name="square" />;
      case MatchEventType.Substitution:
        return <Icon name="check-slot" />;
      case MatchEventType.CornerGoal:
        return <Icon name="corner" />;
      default:
        return null;
    }
  };

  const remain = useMemo(() => {
    return (event.finishSecondsTime ?? 0) - getTotalPeriodTime();
  }, [event.finishSecondsTime, getTotalPeriodTime, clock]);

  return (
    <div key={`cardkey_${event.matchEventId}`} className="jugador-tarjeta">
      <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
        {getEventIcon(event.matchEventType ?? 0)}
        <span>{event.sportTeamNames}</span>
      </div>
      <span>
        {NumberHelper.toTimer(remain)}
      </span>
    </div>
  );
};
