import React, { useCallback, useContext, useEffect, useState } from "react";
import { Col, Form, message, Row } from "antd";
import { CategoryContext } from "../../contexts/CategoriesContext";
import Card from "../../components/Card/Card";
import { ButtonAction } from "../../components/Form/Buttons/ButtonAction";
import Popup from "../../components/Modal/Popup";
import CategoryForm from "./CategoryForm";

export const CategoryCard: React.FC = () => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const { current, edit, cancel, editing, update } =
    useContext(CategoryContext);

  useEffect(() => {
    form.setFieldsValue(current ?? {});
  }, [current, form]);

  const handleEditClick = () => {
    edit();
    setIsPopupVisible(true);
  };

  const handleSaveClick = useCallback((): void => {
    form.submit();
  }, [form]);

  const handleCancelClick = () => {
    form.setFieldsValue(current ?? {});
    cancel();
    setIsPopupVisible(false);
  };

  async function onFinish(values: any): Promise<void> {
    try {
      const result = (await update(values)) ?? false;
      if (result) {
        messageApi.open({
          type: "success",
          content: "Guardado con éxito",
        });
        setIsPopupVisible(false);
        return;
      }
    } catch (err) {
      console.error(err);
    }
    messageApi.open({
      type: "error",
      content: "Error al guardar.",
    });
  }

  return (
    <Card>
      {contextHolder}
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          height: 30,
          marginTop: 5,
        }}
      >
        {!editing && <ButtonAction type="edit" onClick={handleEditClick} />}
      </div>
      <CategoryForm form={form} editing={false} onFinish={onFinish} />

      <Popup
        title="Editar Categoría"
        visible={isPopupVisible}
        onClose={handleCancelClick}
        width={1024}
      >
        <CategoryForm
          form={form}
          editing={true}
          onFinish={onFinish}
          onSaveClick={handleSaveClick}
          onCancelClick={handleCancelClick}
        />
      </Popup>
    </Card>
  );
};
