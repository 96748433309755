import React, { useCallback, useContext, useState } from "react";
import { SportTeamCategoryType } from "../../../../virtualclub/models/models/sportsTeamsCategories/SportTeamCategoryType.enum";
import { SelectSportTeam } from "../../../../components/lookups/SelectSportTeam";
import { SelectSportTeamPopup } from "../../../../components/lookups/SelectSportTeamPopup";
import { CheckMatchContext } from "../CheckMatchContext";
import { MatchContext } from "../../../../contexts/MatchContext";
import { MatchSportTeamView } from "../../../../virtualclub/models/models/matchesSportTeams/MatchSportTeam.view";
interface properties {
  federationTeamId: string;
}

export const CheckMatchPlayersList: React.FC<properties> = ({
  federationTeamId,
}) => {
  const [sportTeamCategoryType, setSportTeamCategoryType] =
    useState<SportTeamCategoryType>();
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);

  const { match } = useContext(MatchContext);
  const { updateMatchSportTeam, matchSportTeams } = useContext(CheckMatchContext);

  const handleClick = useCallback((value: SportTeamCategoryType) => {
    setSportTeamCategoryType(value);
    setIsOpenPopup(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpenPopup(false);
  }, []);

  const handleSelected = useCallback(
    (value: MatchSportTeamView) => {
      // updateMatchSportTeam({
      //   ...(value as MatchSportTeamView),
      //   sportTeamType: sportTeamCategoryType,
      // });
      setIsOpenPopup(false);
    },
    [sportTeamCategoryType, updateMatchSportTeam]
  );

  const staffhName = useCallback((type: SportTeamCategoryType) => {
    if (matchSportTeams.length) {
      const [value] = matchSportTeams.filter(x => x.federationTeamId === federationTeamId && x.sportTeamType === type);
      if (value) {
        return value.sportTeamNames;
      }
    }
    return "No seleccionado";
  }, [federationTeamId, matchSportTeams]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <SelectSportTeam
        sportTeamCategoryType={SportTeamCategoryType.Judge}
        value={staffhName(SportTeamCategoryType.Judge)}
        onClick={(v: SportTeamCategoryType) => handleClick(v)}
        showShirt
      />
      <SelectSportTeam
        sportTeamCategoryType={SportTeamCategoryType.Coach}
        value={staffhName(SportTeamCategoryType.Coach)}
        onClick={(v: SportTeamCategoryType) => handleClick(v)}
        showShirt
      />
      <SelectSportTeam
        sportTeamCategoryType={SportTeamCategoryType.PhysicalTrainer}
        value={staffhName(SportTeamCategoryType.PhysicalTrainer)}
        onClick={(v: SportTeamCategoryType) => handleClick(v)}
        showShirt
      />
      <SelectSportTeam
        sportTeamCategoryType={SportTeamCategoryType.Assistant}
        value={staffhName(SportTeamCategoryType.Assistant)}
        onClick={(v: SportTeamCategoryType) => handleClick(v)}
        showShirt
      />
      <SelectSportTeam
        sportTeamCategoryType={SportTeamCategoryType.Medic}
        value={staffhName(SportTeamCategoryType.Medic)}
        onClick={(v: SportTeamCategoryType) => handleClick(v)}
        showShirt
      />
      <SelectSportTeam
        sportTeamCategoryType={SportTeamCategoryType.Referee}
        value={staffhName(SportTeamCategoryType.Referee)}
        onClick={(v: SportTeamCategoryType) => handleClick(v)}
        showShirt
      />

      <SelectSportTeamPopup
        isOpen={isOpenPopup}
        federationId={match?.accountId}
        sportTeamCategoryType={sportTeamCategoryType}
        onClose={handleClose}
        onSelected={handleSelected}
      />
    </div>
  );
};
