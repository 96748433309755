import { useContext, useEffect, useState } from "react";

import "./EventPopup.css";
import Popup from "../../../components/Modal/Popup";
import { IPartidosPlantel } from "../../../virtualclub/models/models/_obsolete/partido/IPartidoPlantel";
import { Button } from "antd";
import { MatchEventView } from "../../../virtualclub/models/models/matchesEvents/MatchesEvents.view";
import { MatchTimerContext } from '../../../contexts/MatchTimerContext';
import { NumberHelper } from "../../../helpers/NumberHelper";
import { NamesHelper } from '../../../helpers/NamesHelper';
import { StepSelectTeam } from './StepSelectTeam';
import { StepSelectPlayer } from "./StepSelectPlayer";
import { MatchSportTeamView } from '../../../virtualclub/models/models/matchesSportTeams/MatchSportTeam.view';
import { MatchEventType } from '../../../virtualclub/models/models/matchesEvents/MatchEventType.enum';
import { useTimer } from '../../../contexts/TimerContext';
import { FederationTeamView } from '../../../virtualclub/models/models/federationsTeams/FederationTeam.view';

interface propierties {
  eventType: MatchEventType;

  onClose?: () => void;
}

enum Step {
  SelectTeam = 0,
  SelectPlayer = 1,
  Confirmation = 2
}


export const EventPopup: React.FC<propierties> = ({ eventType, onClose }) => {
  const {
    getTotalPeriodTime,
  } = useTimer();
  const [step, setStep] = useState<Step>(Step.SelectTeam);
  const [event, setEvent] = useState<MatchEventView>({});
  const { match, addEvent } = useContext(MatchTimerContext);

  useEffect(() => {
    setEvent((prev) => ({
      matchEventType: eventType,
      timeInSeconds: getTotalPeriodTime(),
      startTime: new Date(),
      deadTime: getTotalPeriodTime() + 60
    }))
  }, []);

  const StepName = [
    "Seleccionar Club",
    "Seleccionar Jugador",
    "Confirmacion"
  ]

  const handlePreviusStep = () => {
    setStep((prev) => prev - 1);
  }

  const handleSelectTeam = (federationTeam?: FederationTeamView) => {
    if (federationTeam?.federationId) {
      setEvent((prev) => ({ ...prev, federationTeamId: federationTeam.federationTeamId }));
      setStep(Step.SelectPlayer);
    }
  }
  const handleSelectPlayer = (player: MatchSportTeamView) => {
    setEvent((prev) => ({
      ...prev
      , matchSportTeamId: player.matchSportTeamId
      , sportTeamNames: player.sportTeamNames
      , sportTeamNumber: player.sportTeamNumber?.toString()
    }));
    setStep(Step.Confirmation);
  }

  const handleSaveEvent = () => {
    addEvent(event);
    onClose?.();
  }

  return (
    <Popup
      visible={true}
      onClose={onClose}
      showCloseButton
      showBackButton={step !== Step.SelectTeam}
      onBack={handlePreviusStep}
      width={600}
    >
      <div className="event-header">
        <span className="event-type">
          {NamesHelper.MatchEventTypeName(eventType)}
        </span>
        <span className="event-step">{StepName[step]}</span>
        <span className="event-time">{NumberHelper.toTimer(event.timeInSeconds ?? 0)}</span>
      </div>

      {step === Step.SelectTeam && (
        <StepSelectTeam onSelectTeam={handleSelectTeam} />
      )}

      {step === Step.SelectPlayer && event?.federationTeamId && (
        <StepSelectPlayer federationTeamId={event.federationTeamId} onSelectPlayer={handleSelectPlayer} />
      )}
      {step === Step.Confirmation && (
        <Button className="event-btn" onClick={handleSaveEvent}>
          Confirmar evento
        </Button>
      )}
    </Popup>
  );
};

