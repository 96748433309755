import React, { useCallback, useEffect, useState } from "react";
import { useSportsTeamsService } from "../../services/useSportsTeamsService";
import { SportTeamCategoryType } from "../../virtualclub/models/models/sportsTeamsCategories/SportTeamCategoryType.enum";
import { Spin, Pagination } from "antd";
import { SearchInput } from "../Search/SearchInput";
import { Row } from "../TableCard/Row";
import { ColText } from "../TableCard/ColText";
import { NamesHelper } from "../../helpers/NamesHelper";
import { ColTag } from "../TableCard/ColTag";
import { SportsTeamsFilters } from "../../virtualclub/models/models/sportsTeams/SportsTeams.filters";
import { SportTeamView } from "../../virtualclub/models/models/sportsTeams/SportTeam.view";

import "./SelectSportTeam.css";
import ShirtPopup from "../../components/Modal/ShirtPopup";
import { Shirt } from "../../components/Shirt/Shirt";

const SportTeamCategoryTypeNames: { [key in SportTeamCategoryType]: string } = {
  [SportTeamCategoryType.NotDefined]: "No definido",
  [SportTeamCategoryType.Player]: "Jugador",
  [SportTeamCategoryType.Judge]: "Juez",
  [SportTeamCategoryType.Coach]: "Entrenador",
  [SportTeamCategoryType.Assistant]: "Asistente",
  [SportTeamCategoryType.PhysicalTrainer]: "Preparador Físico",
  [SportTeamCategoryType.Medic]: "Médico",
  [SportTeamCategoryType.Referee]: "Árbitro",
};

interface properties {
  isOpen?: boolean;
  federationId?: string;
  sportTeamCategoryType?: SportTeamCategoryType;
  teamId?: string;
  value?: string;
  onSelected?: (value: SportTeamView) => void;
  onClose?: () => void;
}

export const SelectSportTeamPopup: React.FC<properties> = ({
  isOpen,
  federationId,
  sportTeamCategoryType,
  teamId,
  onSelected,
  onClose,
}) => {
  const { data, isLoading, list } = useSportsTeamsService();
  const [current, setCurrent] = useState<SportTeamView>();
  const [filters, setFilters] = useState<SportsTeamsFilters>({
    federationId,
    sportTeamCategoryType,
    teamId,
  });
  const categoryTypeName =
    SportTeamCategoryTypeNames[
    sportTeamCategoryType ?? SportTeamCategoryType.NotDefined
    ];

  useEffect(() => {
    if (isOpen) {
      const filters: SportsTeamsFilters = {
        federationId,
        sportTeamCategoryType,
        teamId,
      };
      list(filters);
    }
  }, [isOpen]);

  function handleSearch(value: string): void {
    setFilters((values) => ({ ...values, search: value, page: 1 }));
  }

  function handleChangePage(page: number, pageSize: number): void {
    //gotoPage?.(page);
  }

  const handleRowClick = useCallback(
    (sportTeamCategoryId: string) => {
      const row = data?.data?.find(
        (p) => p.sportTeamCategoryId === sportTeamCategoryId
      );
      if (row) {
        setCurrent(row);
        onSelected?.(row);
      }
    },
    [data?.data, onSelected]
  );
  const renderShirt = () => {
    return <Shirt categoryType={sportTeamCategoryType} />;
  };

  return (
    <ShirtPopup
      visible={isOpen}
      title={`Seleccione un nuevo ${categoryTypeName}`}
      onClose={onClose}
      renderShirt={renderShirt}
    >
      <Spin spinning={isLoading}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            marginBottom: 5,
          }}
        >
          <div style={{ flex: 1, fontWeight: 400 }}>
            {"Listado de plantel técnico"}
          </div>
          <div style={{ flex: 1 }}>
            <SearchInput value={filters?.search} onChange={handleSearch} />
          </div>
        </div>

        {data &&
          data?.data &&
          data?.data.map((player) => (
            <Row
              key={player.sportTeamCategoryId}
              id={player.sportTeamCategoryId ?? ""}
              gender={player.gender}
              onClick={handleRowClick}
              justify
              active={
                player.sportTeamCategoryId === current?.sportTeamCategoryId
              }
            >
              <ColText
                value={player.sportTeamCode ?? ""}
                grayText
                width="100px"
              />
              <ColText value={player.lastnames + ", " + player.firstnames} />
              <ColTag
                value={NamesHelper.CategoryTypeShort(
                  player.sportTeamCategoryType
                )}
              />
              <ColTag value={player.categoryShort}></ColTag>
            </Row>
          ))}

        {data?.limit && (data?.total ?? 0) > data?.limit && (
          <Pagination
            total={data?.total}
            pageSize={data.limit}
            current={data.page}
            onChange={handleChangePage}
          />
        )}
      </Spin>
    </ShirtPopup>
  );
};
