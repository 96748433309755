import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { ReactComponent as BarsIcon } from '../../assets/icons/bars.svg';
import { ReactComponent as CheckSlotIcon } from '../../assets/icons/check-slot.svg';
import { ReactComponent as CircleIcon } from '../../assets/icons/circle.svg';
import { ReactComponent as Grid2Icon } from '../../assets/icons/grid-2.svg';
import { ReactComponent as TriangleIcon } from '../../assets/icons/triangle.svg';
import { ReactComponent as SquareIcon } from '../../assets/icons/square.svg';
import { ReactComponent as LayerGroupIcon } from '../../assets/icons/layer-group.svg';
import { ReactComponent as ListIcon } from '../../assets/icons/list.svg';
import { ReactComponent as MarsIcon } from '../../assets/icons/mars.svg';
import { ReactComponent as PeopleArrowsIcon } from '../../assets/icons/people-arrows.svg';
import { ReactComponent as ScreenUsersIcon } from '../../assets/icons/screen-users.svg';
import { ReactComponent as ShieldCheckIcon } from '../../assets/icons/shield-check.svg';
import { ReactComponent as ShieldIcon } from '../../assets/icons/shield.svg';
import { ReactComponent as TrophyIcon } from '../../assets/icons/trophy.svg';
import { ReactComponent as UserGroupIcon } from '../../assets/icons/user-group.svg';
import { ReactComponent as UsersIcon } from '../../assets/icons/users.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as VenusIcon } from '../../assets/icons/venus.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg';
import { ReactComponent as FutbolIcon } from '../../assets/icons/futbol.svg';
import { ReactComponent as PenIcon } from '../../assets/icons/pen-to-square.svg';
import { ReactComponent as ChevronDownIcon } from '../../assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from '../../assets/icons/chevron-up.svg';
import { ReactComponent as ChevronLeftIcon } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../assets/icons/chevron-right.svg';
import { ReactComponent as CornerIcon } from '../../assets/icons/corner.svg';
import { ReactComponent as PlayIcon } from '../../assets/icons/play.svg';
import { ReactComponent as PauseIcon } from '../../assets/icons/pause.svg';
import { ReactComponent as IdCardIcon } from '../../assets/icons/id-card.svg';
import { ReactComponent as BackIcon } from "../../assets/icons/buttonBack.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/buttonPlus.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/buttonCheck.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/buttonCancel.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/buttonEdit.svg";
import { ReactComponent as DangerIcon } from "../../assets/icons/danger.svg";

export const icons = {
  buttonBack: BackIcon,
  buttonPlus: PlusIcon,
  buttonCheck: CheckIcon,
  buttonCancel: CancelIcon,
  buttonEdit: EditIcon,

  search: SearchIcon,
  calendar: CalendarIcon,
  bars: BarsIcon,
  'check-slot': CheckSlotIcon,
  circle: CircleIcon,
  'grid-2': Grid2Icon,
  triangle: TriangleIcon,
  square: SquareIcon,
  'layer-group': LayerGroupIcon,
  list: ListIcon,
  mars: MarsIcon,
  'people-arrows': PeopleArrowsIcon,
  'screen-users': ScreenUsersIcon,
  'shield-check': ShieldCheckIcon,
  shield: ShieldIcon,
  trophy: TrophyIcon,
  'user-group': UserGroupIcon,
  users: UsersIcon,
  user: UserIcon,
  venus: VenusIcon,
  clock: ClockIcon,
  futbol: FutbolIcon,
  pen: PenIcon,
  'chevron-down': ChevronDownIcon,
  'chevron-up': ChevronUpIcon,
  'chevron-left': ChevronLeftIcon,
  'chevron-right': ChevronRightIcon,
  corner: CornerIcon,
  play: PlayIcon,
  pause: PauseIcon,
  'id-card': IdCardIcon,
  danger: DangerIcon,
};
