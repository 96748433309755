import React, { useContext, useState } from "react";
import "../SideRightCard.css";
import { MembersContext } from "../../contexts/MembersContext";
import Label from "../../components/Form/Label/Label";
import { MembersActivityPopup } from "./MembersActivityPopup";
import MemberActivity from "./MemberActivity";
import { ButtonAction } from "../../components/Form/Buttons/ButtonAction";
import { MemberSportTeam } from "../../virtualclub/models/models/members/Member.sportteam";

interface properties {}

export const MembersActivities: React.FC<properties> = () => {
  const { current, sportsTeams } = useContext(MembersContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSportTeam, setSelectedSportTeam] =
    useState<MemberSportTeam | null>(null);

  const handleOpenPopup = (sportTeam?: MemberSportTeam) => {
    setSelectedSportTeam(sportTeam || null);
    setIsModalVisible(true);
  };
  const handleClosePopup = () => {
    setSelectedSportTeam(null);
    setIsModalVisible(false);
  };

  return (
    <div>
      <div style={{ maxHeight: "250px", overflow: "auto" }}>
        {sportsTeams?.length ? (
          sportsTeams.map((record) => (
            <MemberActivity
              sportTeam={record}
              gender={current?.gender!}
              onClick={() => handleOpenPopup(record)}
            />
          ))
        ) : (
          <Label
            fontSize="14"
            fontWeight="500"
            gray
            style={{ marginBottom: 20 }}
          >
            No hay cuenta asociada
          </Label>
        )}
      </div>
      <div className="sideright-card-options">
        <ButtonAction size={42} type="plus" onClick={handleOpenPopup} />
      </div>
      <MembersActivityPopup
        visible={isModalVisible}
        onClose={handleClosePopup}
        sportTeam={selectedSportTeam}
      />
    </div>
  );
};
