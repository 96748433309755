import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Layout,
  Space,
  Spin,
  message,
} from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginScreen.css";
import logoImage from "./assets/logo.svg";
import appImage from "./assets/appImage.svg";
import { AuthRequest } from "../../../virtualclub/models/models/login/AuthRequest";
import { routes } from "../../../constants/RouteConstants";
import { PublicService } from "../../../services/PublicService";
import Label from "../../../components/Form/Label/Label";

export interface LoginProps {
  urlRedirect?: string;
}

export const LoginScreen: React.FC<LoginProps> = ({ urlRedirect }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const [formData, setFormData] = useState<AuthRequest>({
    username: "",
    password: "",
  });

  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const [messageApi, contextHolder] = message.useMessage();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onFinish = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setHasError(false);

    try {
      const userInfo = await PublicService.Authenticate(formData);
      if (userInfo) {
        navigate(routes.Matches);
      }
    } catch (err) {
      console.log(err);
      messageApi.open({
        type: "error",
        content: "Verifique el usuario y/o la contraseña.",
      });
    }
    setLoading(false);
  };

  return (
    <div className="login-layout">
      <div className="login-image-conteiner">
        <img src={appImage} alt="App" className="login-app-image" />
      </div>

      <div className="login-right">
        <div className="login-right-content">
          <img src={logoImage} alt="Logo" />
          <div className="login-text">
            <h2>
              <strong>ORGANIZA Y GESTIONA</strong> torneos <br /> deportivos sin
              complicaciones
            </h2>
          </div>
        </div>
      </div>
      <div className="login-left">
        <div className="login-left-content">
          <span className="login-left-text"> Iniciar Sesion</span>
          <form onSubmit={onFinish} className="login-form">
            <div className="login-input-container">
              <label htmlFor="username" className="login-input-text">
                Email / nombre de usuario
              </label>

              <input
                className="login-input"
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                placeholder="Email / nombre de usuario"
                required
              />
            </div>

            <div className="login-input-container">
              <label htmlFor="password" className="login-input-text">
                Contraseña
              </label>

              <input
                className="login-input"
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                placeholder="Contraseña"
                required
              />
            </div>

            <a className="login-label" href="#">
              Olvidé mi contraseña
            </a>

            <div className="submit-container">
              <button className="login-form-button">Ingresar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
