import { useContext, useState } from "react";
import { SearchInput } from "../../components/Search/SearchInput";
import { TitlePage } from "../../components/Screen/TitlePage";
import { Button, Checkbox, Drawer } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { TeamsContext } from "../../contexts/TeamsContext";

interface properties { }

export const TeamsSearch: React.FC<properties> = () => {
  const { filters, updateFilters } = useContext(TeamsContext);
  const [isDrawerVisible, setDrawerVisible] = useState(false);

  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });

  const toggleDrawer = () => {
    setDrawerVisible(!isDrawerVisible);
  };

  function handleSearch(value: string): void {
    updateFilters?.({ search: value });
  }

  return (
    <>
      <TitlePage title="Listado de Equipos">
        {isSmallScreen ? (
          <Button
            icon={<FilterOutlined />}
            onClick={toggleDrawer}
            style={{ marginBottom: 5 }}
          >
            Filtros
          </Button>
        ) : (
          <>
            <SearchInput value={filters?.search} onChange={handleSearch} />
          </>
        )}
      </TitlePage>
      <Drawer
        title="Filtros"
        placement="top"
        closable={true}
        onClose={toggleDrawer}
        visible={isDrawerVisible}
        height={200}
      >
        <div style={{ marginBottom: 20 }}>
          <SearchInput value={filters?.search} onChange={handleSearch} />
        </div>
      </Drawer>
    </>
  );
};
