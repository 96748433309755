import React, { useContext } from "react";

import "./Cronometro.css";
import { MatchTimerContext } from '../../contexts/MatchTimerContext';
import { AvatarImage } from '../../components/Images/AvatarImage';
import { NumberHelper } from "../../helpers/NumberHelper";
import { useTimer } from '../../contexts/TimerContext';
import { DateHelper } from "../../helpers/DateHelper";

export const MatchTimerHeader: React.FC = () => {
  const { match } = useContext(MatchTimerContext);
  const {
    state,
    getTime,
    getTotalPeriodTime,
    getTotalTimeOut,
    getRemainingTime
  } = useTimer();

  return (
    <>
      <div className="cronometro-container">
        <div className="team-container">
          <AvatarImage id={match?.homeTeamId} />
        </div>
        <div className="small-timer">{NumberHelper.toTimer(getRemainingTime())}</div>
        <div className="small-timer">{DateHelper.formatTimeLong(state.lastStatus)}</div>
        <div className="match-timer">
          <div className="match-stage">{state.status} {state.currentPeriod}</div>
          <div className="big-timer">{NumberHelper.toTimer(getTime())}</div>
        </div>
        <div className="small-timer">{NumberHelper.toTimer(getTotalTimeOut())}</div>
        <div className="small-timer">{NumberHelper.toTimer(getTotalPeriodTime())}</div>
        <div className="team-container">
          <AvatarImage id={match?.awayTeamId} />
        </div>
      </div>
      <div className="team-card">
        <span className="team-name">
          {match?.homeTeam?.team?.teamName} {match?.homeSubTeam}
        </span>
        <div className="team-score-container">
          <span className="team-score">{match?.homeScore}</span>
          <span className="team-score">{match?.awayScore}</span>
        </div>
        <span className="team-name team-name-left">
          {match?.awayTeam?.team?.teamName} {match?.awaySubTeam}
        </span>
      </div>
      {/* <div>
        start:{match?.cronoStart?.toString()} - elapsed:{match?.cronoElapsedTime} - dead:{match?.cronoDeadTime} - status:{match?.cronoStatus}
      </div>
      <div>
        elapsedTime [{elapsedTime}] - deadTime [{deadTime}]
      </div> */}
    </>
  );
};

