import 'reflect-metadata';
import { IsDate, IsEnum } from 'class-validator';
import { MatchStatus } from './MatchStatus.enum';
import { plainToInstance, Type } from 'class-transformer';
import { FederationTeamView } from '../federationsTeams/FederationTeam.view';
import { MatchTeamStatus } from './MatchTeamStatus.enum';
import { TimerMatchStatus } from '../matchTimer/TimerMatchStatus.enum';

export class MatchView {
  matchId?: string;
  accountId?: string;
  sportId?: number;
  tournamentId?: string;
  matchCode?: string;
  matchSeason?: number;
  matchNumber?: number;
  gender?: number;
  categoryId?: number;
  category?: any;
  dateTimePlay?: Date;
  stadium?: string;

  homeFederationTeamId?: string;
  homeTeamId?: string;
  @IsEnum(MatchTeamStatus)
  homeStatus?: MatchTeamStatus;
  homeSubTeam?: number;
  homeTeam?: FederationTeamView;
  homeScore?: number;

  awayFederationTeamId?: string;
  awayTeamId?: string;
  @IsEnum(MatchTeamStatus)
  awayStatus?: MatchTeamStatus;
  awaySubTeam?: number;
  awayTeam?: FederationTeamView;
  awayScore?: number;

  @IsEnum(MatchStatus)
  matchStatus?: MatchStatus;

  maximumPlayers?: number;
  minimumPlayers?: number;
  numberPeriods?: number;
  periodsTime?: number;
  intervalTime?: number;
  extraTimeType?: number;
  extraTime?: number;
  penalties?: number;
  penaltiesCount?: number;

  cronoPeriod?: number;
  cronoPlaying?: boolean;
  cronoStatus?: TimerMatchStatus;

  @Type(() => Date)
  cronoStart?: Date;
  cronoElapsedTime?: number;
  cronoTimeOut?: number;

  static fromPlain(plain: MatchView): MatchView {
    return plainToInstance(MatchView, plain);
  }
}
