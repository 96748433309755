import React, { useEffect, useState, useMemo } from "react";
import { DefaultOptionType } from "antd/es/select";
import { SelectItem } from "../Select/SelectItem";
import { SelectExt, SelectExtProps } from "../SelectExt";
import { SportImage } from "../Images/SportImage";
import styles from "./Selects.module.css";

interface properties extends SelectExtProps {
  showAll?: boolean;
  textAll?: string;
  isFilter?: boolean;
}

export const SelectSport: React.FC<properties> = ({
  showAll = false,
  textAll = showAll ? "Seleccione un deporte" : "Deporte",
  value,
  readOnly,
  disabled,
  onChange,
  type,
  isFilter = false,
  ...props
}) => {
  const items: SelectItem[] = [
    { value: 2, label: "Futbol" },
    { value: 1, label: "Hockey sobre Cesped" },
    { value: 3, label: "Hockey sobre Hielo" },
    { value: 4, label: "Rugby" },
    { value: 0, label: "Otros" },
  ];

  const options = useMemo(() => {
    const its: SelectItem[] =
      items?.map((item) => {
        return {
          value: item.value,
          label: (
            <span style={{ display: "flex", alignItems: "center", gap: 5 }}>
              {(item?.value as number) > 0 && (
                <SportImage
                  sportId={Number(item.value)}
                  style={{ width: 25, height: 25 }}
                />
              )}
              {item.label}
            </span>
          ),
        } as unknown as SelectItem;
      }) ?? [];
    if (showAll) {
      its.unshift({ value: -1, label: textAll });
    }
    return its;
  }, [items, showAll, textAll]);

  const text = useMemo(() => {
    const exists = options?.find((x) => x.value === value);
    return exists?.label ?? "";
  }, [options, value]);

  const [selectedValue, setSelectedValue] = useState(value ?? items[0].value);

  useEffect(() => {
    if (value !== undefined) {
      setSelectedValue(value);
    }
  }, [value]);

  function onChangeValue(
    value: any,
    option: DefaultOptionType | DefaultOptionType[]
  ): void {
    setSelectedValue(value);
    onChange?.(value, option);
  }

  return (
    <>
      {!readOnly && !disabled ? (
        <SelectExt
          {...props}
          options={options}
          value={selectedValue}
          onChange={onChangeValue}
          type={type}
          className={isFilter ? styles.isFilter : ""}
        />
      ) : (
        <>{text}</>
      )}
    </>
  );
};
