import React, { useContext } from "react";
import { CheckMatchContext } from "../CheckMatchContext";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../constants/RouteConstants";
import "./CheckMatchMenu.css";
import { Icon } from "../../../../components/Icons/Icon";

interface Properties {}

export const CheckMatchMenu: React.FC<Properties> = () => {
  const { match } = useContext(CheckMatchContext);
  const navigate = useNavigate();

  const openCronus = () => {
    if (match?.matchId) {
      window.open(
        routes.MatchTimerManager.replace(":matchId", match.matchId),
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  return (
    <div className="check-match-menu">
      <div className="menu-grid">
        <div className="menu-item-large" onClick={() => openCronus()}>
          <div className="icon-container">
            <Icon name="play" size={40} className="icon-play" />
          </div>
          Comenzar Partido
        </div>
        <div
          className="menu-item-small"
          onClick={() => console.log("Suspender partido")}
        >
          <Icon name="danger" size={40} />
          Suspender partido
        </div>

        <div
          className="menu-item-small"
          onClick={() => console.log("Finalizar")}
        >
          <Icon name="check-slot" color="#0069FF" size={40} />
          Finalizar
        </div>
        <div
          className="menu-item-small"
          onClick={() => console.log("Modificar eventos")}
        >
          <Icon name="pen" color="#FF7745" size={40} />
          Modificar eventos
        </div>
        <div
          className="menu-item-small"
          onClick={() => console.log("Imprimir planilla")}
        >
          Imprimir planilla
        </div>
      </div>
    </div>
  );
};
