import { Row } from "../../../components/TableCard/Row";
import { ColText } from "../../../components/TableCard/ColText";
import { ColTag } from "../../../components/TableCard/ColTag";
import { ColShirt } from "../../../components/TableCard/ColShirt";
import { ColButton } from "../../../components/TableCard/ColButton";
import { useContext } from "react";
import { PreSelectTeamContext } from "../preSelectTeam/PreSelectTeamContext";
import { ColField } from '../../../components/TableCard/ColField';
import { SportTeamCategoryAvailableView } from '../../../virtualclub/models/models/sportsTeamsCategories/SportTeamCategoryAvailableView';
interface properties {
  value: SportTeamCategoryAvailableView;
  selected?: boolean;
  onClick?: (value: SportTeamCategoryAvailableView) => void;
  onChangeShirtNumber?: (value: SportTeamCategoryAvailableView) => void;
  onChangePosition?: (value: SportTeamCategoryAvailableView) => void;
}

export const AvailablePlayer: React.FC<properties> = ({
  value,
  selected,
  onClick,
  onChangeShirtNumber,
  onChangePosition
}) => {
  const { addPlayer, addedPlayers } = useContext(PreSelectTeamContext);

  function handleClick(): void {
    const shirtNumberUsed = addedPlayers.map(x => x.sportTeamNumber);
    if (shirtNumberUsed.includes(value.shirtNumber)) {
      onChangeShirtNumber?.(value);
    } else {
      addPlayer?.(value);
    }
  }
  function handleChangeShirtNumber(): void {
    onChangeShirtNumber?.(value);
  }

  function handleChangePosition(): void {
    onChangePosition?.(value);
  }

  return (
    <>
      <Row id={value.sportTeamCategoryId ?? ""} active={selected} justify>
        <ColText value={value.sportTeamCategoryCode ?? ""} grayText width="70px" />
        <ColText value={value.lastnames + "," + value.firstnames} />
        <ColShirt value={value.shirtNumber?.toString()} onClick={handleChangeShirtNumber} />
        <ColField value={value.position} onClick={handleChangePosition} />
        <ColButton width="30px" icon="+" onClick={handleClick} />
      </Row>
    </>
  );
};
