import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';

export interface TimerStatus {
  lastStatus?: Date;
  status: 'stopped' | 'running' | 'intermission' | 'pause' | 'finished';
  periods: number;
  secondsPerPeriod: number;
  secondsPerBreakTime: number;
  currentPeriod: number;
  elapsedTime: number;
  timeOutElapsed: number;
}

const initialState: TimerStatus = {
  lastStatus: undefined,
  status: 'stopped',
  periods: 0,
  secondsPerPeriod: 0,
  secondsPerBreakTime: 0,
  currentPeriod: 0,
  elapsedTime: 0,
  timeOutElapsed: 0,
};
interface TimerContextValue {
  state: TimerStatus;
  clock: number;
  setState: (config: TimerStatus) => void;
  startTimer: () => void;
  pauseTimer: () => void;
  resetTimer: () => void;
  getTime: () => number;
  getRemainingTime: () => number;
  getTotalPeriodTime: () => number;
  getTotalTimeOut: () => number;
  calculateTotalPeriodTime: (period: number, timer: number) => number;
}

const TimerContext = createContext<TimerContextValue | null>(null);

export const TimerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  //const [state, setState] = useLocalStorage<TimerStatus>('hockey-timer', initialState);
  const [state, setState] = useState<TimerStatus>(initialState);
  const [clock, setClock] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout>();

  const getElapsed = useCallback((): number => {
    if (!state.lastStatus) return 0;
    return Math.floor((new Date().getTime() - state.lastStatus.getTime()) / 1000);
  }, [state.lastStatus]);


  const updateTimer = useCallback(() => {
    setState(prev => {
      const elapsed = getElapsed();
      const totalElapsed = prev.elapsedTime + elapsed;
      setClock((c) => c + 1);

      switch (prev.status) {
        case "running":
          if (totalElapsed >= prev.secondsPerPeriod) {
            return {
              ...prev,
              status: (prev.currentPeriod === prev.periods ? "finished" : "intermission"),
              elapsedTime: 0,
              lastStatus: new Date(),
            };
          }
          break;
        case "intermission":
          if (totalElapsed >= prev.secondsPerBreakTime) {
            return {
              ...prev,
              status: 'stopped',
              currentPeriod: prev.currentPeriod + 1,
              timeOutElapsed: prev.secondsPerBreakTime,
            };
          }
          break;
        case "pause":
          break;
      }

      return prev;
    });
  }, [getElapsed, setState]);

  useEffect(() => {
    if (state.status === 'running' || state.status === 'intermission' || state.status === 'pause') {
      intervalRef.current = setInterval(updateTimer, 1000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [state.status, updateTimer]);

  const startTimer = useCallback(() => {
    setState(prev => ({
      ...prev,
      status: 'running',
      timeOutElapsed: prev.timeOutElapsed + (prev.status === 'pause' ? getElapsed() : 0),
      lastStatus: new Date(),
    }));
  }, [getElapsed, setState]);

  const pauseTimer = useCallback(() => {
    setState(prev => ({
      ...prev,
      elapsedTime: prev.elapsedTime + getElapsed(),
      status: 'pause',
      lastStatus: new Date(),
    }));
  }, [getElapsed, setState]);

  const resetTimer = useCallback(() => {
    setState(initialState);
  }, [setState]);

  // Obtiene el tiempo del periodo actual
  const getTime = useCallback(() => {
    if (state.status === 'running' || state.status === 'intermission') {
      return state.elapsedTime + getElapsed();
    }
    return state.elapsedTime;
  }, [state.status, state.elapsedTime, getElapsed]);

  const getRemainingTime = useCallback(() => {
    const duration = state.status === 'intermission' ? state.secondsPerBreakTime : state.secondsPerPeriod;
    //const elapsed = state.status === 'intermission' ? state.timeOutElapsed : getTime();

    return Math.max(0, duration - getTime());
  }, [state.status, state.secondsPerBreakTime, state.secondsPerPeriod, getTime]);

  // Calcula el tiempo total de todos los periodos más el actual
  const calculateTotalPeriodTime = useCallback((period: number, timer: number) => {
    const total = Math.max(0, (period - 1) * state.secondsPerPeriod + timer);
    return total;
  }, [state.secondsPerPeriod]);

  const getTotalPeriodTime = useCallback(() => {
    return calculateTotalPeriodTime(state.currentPeriod, getTime());
  }, [calculateTotalPeriodTime, state.currentPeriod, getTime]);

  const getTotalTimeOut = useCallback(() => {
    return state.status === 'pause'
      ? state.timeOutElapsed + getElapsed()
      : state.timeOutElapsed;
  }, [state, getElapsed]);

  const contextValue = {
    state,
    clock,
    startTimer,
    pauseTimer,
    resetTimer,
    getTime,
    getRemainingTime,
    getTotalPeriodTime,
    getTotalTimeOut,
    calculateTotalPeriodTime,
  };

  return (
    <TimerContext.Provider value={{ ...contextValue, setState }}>
      {children}
    </TimerContext.Provider>
  );
};

export const useTimer = () => {
  const context = useContext(TimerContext);
  if (!context) {
    throw new Error('useTimer must be used within a TimerProvider');
  }
  return context;
};