import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'antd';
import './MatchTimerButtons.css';
import { MatchTimerContext } from '../../contexts/MatchTimerContext';
import { EventPopup } from './EventPopup/EventPopup';
import { MatchEventType } from '../../virtualclub/models/models/matchesEvents/MatchEventType.enum';
import { Icon } from '../../components/Icons/Icon';
import { useTimer } from '../../contexts/TimerContext';

const MatchTimerButtons: React.FC = () => {
  const { match } = useContext(MatchTimerContext);
  const {
    state,
    startTimer,
    pauseTimer,
    getTime,
    getTotalPeriodTime,
    getTotalTimeOut,
    getRemainingTime
  } = useTimer();
  const [executeEventType, setExecuteEventType] = useState<MatchEventType>();

  // const { triggerEvent, startCrono, pauseCrono, isRunning } =
  //   useContext(CronoContext);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // switch (event.key) {
      //   case "q":
      //     triggerEvent(EventoTipo.Tarjeta_Verde);
      //     break;
      //   case "a":
      //     triggerEvent(EventoTipo.Tarjeta_Amarilla);
      //     break;
      //   case "z":
      //     triggerEvent(EventoTipo.Tarjeta_Roja);
      //     break;
      //   case "o":
      //     triggerEvent(EventoTipo.Tarjeta_Verde);
      //     break;
      //   case "k":
      //     triggerEvent(EventoTipo.Tarjeta_Amarilla);
      //     break;
      //   case "m":
      //     triggerEvent(EventoTipo.Tarjeta_Roja);
      //     break;
      //   default:
      //     break;
      // }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleExecuteEvent = (eventType: MatchEventType) => {
    setExecuteEventType(eventType);
  };

  return (
    <>
      <div className='crono-buttons-container'>
        {state.status === "running" ? (
          <Button
            type='primary'
            icon={<Icon name='pause' />}
            size='large'
            className='play-button'
            onClick={pauseTimer}
          />
        ) : (
          <Button
            type='primary'
            icon={<Icon name='play' />}
            size='large'
            className='play-button'
            onClick={startTimer}
          />
        )}
        <div className='small-buttons-container'>
          <Button
            type='primary'
            icon={<Icon name='triangle' />}
            className='smallbtn'
            onClick={() => handleExecuteEvent(MatchEventType.GreenCard)}
          />
          <Button
            type='primary'
            icon={<Icon name='circle' />}
            className='smallbtn '
            onClick={() => handleExecuteEvent(MatchEventType.YellowCard)}
          />
          <Button
            type='primary'
            icon={<Icon name='square' />}
            className='smallbtn '
            onClick={() => handleExecuteEvent(MatchEventType.RedCard)}
          />
          <Button
            type='primary'
            icon={<Icon name='futbol' />}
            className='smallbtn'
            onClick={() => handleExecuteEvent(MatchEventType.Goal)}
          />
          <Button
            type='primary'
            icon={<Icon name='check-slot' />}
            className='smallbtn'
            onClick={() => handleExecuteEvent(MatchEventType.Substitution)}
          />
          <Button
            type='primary'
            icon={<Icon name='corner' />}
            className='smallbtn'
            onClick={() => handleExecuteEvent(MatchEventType.CornerGoal)}
          />
        </div>
      </div>
      {executeEventType && (
        <EventPopup
          eventType={executeEventType}
          onClose={() => setExecuteEventType(undefined)}
        />
      )}
    </>
  );
};

export default MatchTimerButtons;
