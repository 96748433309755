import React, { createContext, useCallback, useMemo, useState } from 'react';
import { useAsyncCall } from '../../../hooks/useAsyncCall';
import { PlayersFilters } from '../../../virtualclub/models/models/teamMembers/PlayersFilters';
import { SportTeamView } from '../../../virtualclub/models/models/sportsTeams/SportTeam.view';
import { useMatchesService } from '../../../services/useMatchesService';
import { MatchView } from '../../../virtualclub/models/models/matches/Match.view';
import { AuthorizationHelper } from '../../../helpers/AuthorizationHelper';
import { FederationTeamView } from '../../../virtualclub/models/models/federationsTeams/FederationTeam.view';
import { MatchSportTeamView } from '../../../virtualclub/models/models/matchesSportTeams/MatchSportTeam.view';
import { SportTeamCategoryType } from '../../../virtualclub/models/models/sportsTeamsCategories/SportTeamCategoryType.enum';
import { useSportsTeamsCategoriesService } from '../../../services/useSportsTeamsCategoriesService';
import { useTeamsService } from '../../../services/useTeamsService';
import { MatchSportTeamStatus } from '../../../virtualclub/models/models/matchesSportTeams/MatchSportTeamStatus.enum';
import { MatchTeamStatus } from '../../../virtualclub/models/models/matches/MatchTeamStatus.enum';
import {
  SportTeamCategoryAvailableView,
  SportTeamCategoryAvailableFilter,
} from '../../../virtualclub/models/models/sportsTeamsCategories/SportTeamCategoryAvailableView';

interface Props {
  match?: MatchView;
}

export const PreSelectTeamContext = createContext<PreSelectTeamContextValue>(
  {} as PreSelectTeamContextValue
);

export const PreSelectTeamContextProvider: React.FC<
  React.PropsWithChildren<Props>
> = ({ children, match }) => {
  const [auth] = useState(new AuthorizationHelper());
  const { getTeamIdsByAccountId } = useTeamsService();
  const { getSportsTeams, selectSportTeam, updateSportTeam, removeSportTeam, closePreselect } = useMatchesService();
  const { updateCategory, getAvailables } = useSportsTeamsCategoriesService();

  const [federationTeam, setFederationTeam] = useState<FederationTeamView>();
  const [loading, setLoading] = useState<boolean>(false);
  const [enabled, setEnabled] = useState(true);

  const [getStaff, setStaff] = useState<MatchSportTeamView[]>([]);
  const [availablePlayers, setAvailablePlayers] = useState<SportTeamCategoryAvailableView[]>([]);
  const [selectedPlayers, setSelectedPlayers] = useState<MatchSportTeamView[]>([]);

  const loadData = useCallback(async () => {
    if (match) {

      // trae los equipos disponibles de la cuenta actual
      const teamIds = await getTeamIdsByAccountId();

      // Setea la federacion segun los equipos de la cuenta
      // TODO: validar si la cuenta tieen los dos equipos
      const currentFederationTeam = teamIds.includes(match.homeTeamId!)
        ? match.homeTeam
        : match.awayTeam;
      setFederationTeam(currentFederationTeam);

      // Setea el status actual de la lista
      const currentEnabled = teamIds.includes(match.homeTeamId!)
        ? match.homeStatus
        : match.awayStatus;
      setEnabled(currentEnabled !== MatchTeamStatus.PlayersSubmitted);

      // Cargar los jugadores seleccionados
      const selected = await getSportsTeams(
        match.matchId!,
        currentFederationTeam?.federationTeamId
      );
      const sortedItems = selected.sort(
        (a, b) => (a.sportTeamPosition ?? 0) - (b.sportTeamPosition ?? 0)
      );
      // Setea solo los jugadores que no estan borrados
      setSelectedPlayers(
        sortedItems.filter(
          (x) =>
            x.sportTeamType === SportTeamCategoryType.Player &&
            (x.matchSportTeamStatus ?? 0) > MatchSportTeamStatus.Deleted
        )
      );
      // Setea el staff que no esta borrado
      setStaff(
        sortedItems.filter(
          (x) =>
            x.sportTeamType !== SportTeamCategoryType.Player &&
            (x.matchSportTeamStatus ?? 0) > MatchSportTeamStatus.Deleted
        )
      );

      // trae la lista de jugadores disponibles descartando los ya seleccionados
      const excludeIds = selected.map((x) => x.sportTeamCategoryId).join(',');
      const filters: SportTeamCategoryAvailableFilter = {
        federationTeamId: currentFederationTeam?.federationTeamId,
        sportTeamCategoryType: SportTeamCategoryType.Player,
        gender: match.gender,
        excludeIds,
        page: 0,
        limit: 100,
      };
      const resultAvailables = await getAvailables(filters);
      const data = resultAvailables?.data;
      setAvailablePlayers(data ?? []);
    }
  }, [match, getTeamIdsByAccountId, getSportsTeams, getAvailables]);

  const loader = useAsyncCall(loadData, [match]);

  //#region CRUD MatchSportTeam
  const insertCategory = useCallback(
    async (matchId: string, sportTeamCategoryId: string) => {
      const result = await selectSportTeam(matchId, sportTeamCategoryId);
      if (result) {
        return result;
      }
      return;
    },
    [selectSportTeam]
  );

  const updatedCategory = useCallback(
    async (
      values: SportTeamCategoryAvailableView
    ): Promise<SportTeamCategoryAvailableView | undefined> => {
      if (!values.sportTeamCategoryId || !values) {
        return undefined;
      }
      const result = await updateCategory(values.sportTeamCategoryId, values);
      if (result) {
        setAvailablePlayers((prev) =>
          prev.map((item) =>
            item.sportTeamCategoryId !== values.sportTeamCategoryId
              ? item
              : { ...item, ...values }
          )
        );
        return values;
      }
      return undefined;
    },
    [updateCategory]
  );

  //#endregion CRUD MatchSportTeam

  //#region Availables players
  const addPlayer = useCallback(
    async (sportTeamCategory: SportTeamCategoryAvailableView) => {
      if (!match?.matchId || !sportTeamCategory.sportTeamCategoryId) {
        return;
      }

      const isPositionTaken = selectedPlayers.some(
        (player) =>
          player.sportTeamPosition === sportTeamCategory.position &&
          player.matchSportTeamStatus
      );
      const updatedPlayer: MatchSportTeamView = {
        ...sportTeamCategory,
        playerStatus: isPositionTaken ? 0 : 1,
      };
      const result = await insertCategory(match?.matchId, sportTeamCategory.sportTeamCategoryId);
      if (result) {
        setAvailablePlayers((prevAvailablePlayers) =>
          prevAvailablePlayers.filter(
            (p) => p.sportTeamCategoryId !== sportTeamCategory.sportTeamCategoryId
          )
        );
        setSelectedPlayers((prevAddedPlayers) => [
          ...prevAddedPlayers,
          { ...updatedPlayer, ...result },
        ]);
      }
    },
    [insertCategory, match?.matchId, selectedPlayers]
  );

  const removePlayer = useCallback(
    async (player: MatchSportTeamView) => {
      if (!match?.matchId) {
        return;
      }
      const result = await removeSportTeam(player);
      if (result) {
        setSelectedPlayers((prevAddedPlayers) =>
          prevAddedPlayers.filter((p) => p.sportTeamCategoryId !== player.sportTeamCategoryId)
        );
        const names = player.sportTeamNames?.split(",");
        const availablePlayer: SportTeamCategoryAvailableView = {
          sportTeamCategoryId: player.sportTeamCategoryId,
          federationTeamId: player.federationTeamId,
          //categoryId:player,
          //sportId:player,
          sportTeamCategoryCode: player.sportTeamCode,
          lastnames: names?.[0],
          firstnames: names?.[1],
          gender: player.gender,
          sportTeamCategoryType: SportTeamCategoryType.Player,
          position: player.sportTeamPosition,
          shirtNumber: player.sportTeamNumber,
          observations: player.observations,
          //sportTeamCategoryStatus: player.matchSportTeamStatus,
        };
        setAvailablePlayers((prevAvailablePlayers) => [
          ...prevAvailablePlayers,
          availablePlayer
        ]);
      }
    },
    [match?.matchId, removeSportTeam]
  );
  //#endregion Availables players

  //#region players selected
  const updateMatchSportTeam = useCallback(
    async (player: MatchSportTeamView): Promise<boolean> => {
      const result = await updateSportTeam(player, match?.matchId ?? '');
      if (result) {
        setSelectedPlayers((values) =>
          values.map((p) => (p.matchSportTeamId !== player.matchSportTeamId ? p : player))
        );
        return true;
      }
      return false;
    },
    [match?.matchId, updateSportTeam]
  );
  //#endregion players selected

  //#region staff selected
  const updateStaff = useCallback(
    async (staff: MatchSportTeamView): Promise<boolean> => {
      let result;
      if (staff.matchSportTeamId) {
        result = await updateSportTeam(staff, match?.matchId ?? '');
      } else {
        result = await insertCategory(match?.matchId!, staff.sportTeamCategoryId!);
      }
      if (result) {
        setStaff((prev) =>
          prev.map((p) => (p.sportTeamType !== staff.sportTeamType ? p : staff))
        );
        return true;
      }
      return false;
    },
    [match?.matchId, updateSportTeam]
  );
  //#endregion players selected

  const isValid = useCallback((): boolean => {
    if (!match?.matchId) {
      return false;
    }
    return (
      (selectedPlayers.length >= (match.minimumPlayers ?? 0) &&
        selectedPlayers.length <= (match?.maximumPlayers ?? 999)) ??
      false
    );
  }, [
    match?.matchId,
    match?.maximumPlayers,
    match?.minimumPlayers,
    selectedPlayers.length,
  ]);

  const selectCaptain = useCallback(
    async (player: MatchSportTeamView) => {
      await updateMatchSportTeam({ ...player, isCaptain: 1 });

      setSelectedPlayers((prevs) =>
        prevs.map((row) => ({
          ...row,
          isCaptain: row.matchSportTeamId === player.matchSportTeamId ? 1 : 0,
        }))
      );
    },
    [updateMatchSportTeam]
  );

  const captain = useMemo(() => {
    return selectedPlayers.find((x) => x.isCaptain === 1);
  }, [selectedPlayers]);

  const closedPreselect = useCallback(
    async () => {
      if (!match?.matchId || !federationTeam?.federationTeamId) {
        return;
      }
      await closePreselect(match.matchId, federationTeam.federationTeamId);
    },
    [closePreselect, federationTeam?.federationTeamId, match?.matchId]
  );

  const contextValue = useMemo<PreSelectTeamContextValue>(
    () => ({
      loading,
      match,
      availablePlayers,
      addedPlayers: selectedPlayers,
      isValid: isValid(),
      captain,
      enabled,
      updatedCategory,
      addPlayer,
      updateMatchSportTeam,
      getStaff,
      updateStaff,
      removePlayer,
      selectCaptain,
      closedPreselect
    }),
    [loading, match, availablePlayers, selectedPlayers, isValid, captain, enabled, updatedCategory, addPlayer, updateMatchSportTeam, getStaff, updateStaff, removePlayer, selectCaptain, closedPreselect]
  );

  return (
    <PreSelectTeamContext.Provider value={contextValue}>
      {children}
    </PreSelectTeamContext.Provider>
  );
};

export interface PreSelectTeamContextValue {
  loading?: boolean;
  match?: MatchView;
  filters?: PlayersFilters;
  availablePlayers: SportTeamCategoryAvailableView[];
  addedPlayers: MatchSportTeamView[];
  captain?: MatchSportTeamView;
  isValid: boolean;
  enabled: boolean;
  updatedCategory?: (values: SportTeamCategoryAvailableView) => Promise<SportTeamCategoryAvailableView | undefined>;
  addPlayer: (player: SportTeamView) => void;
  updateMatchSportTeam: (player: MatchSportTeamView) => Promise<boolean>;
  getStaff: MatchSportTeamView[];
  updateStaff: (staff: MatchSportTeamView) => Promise<boolean>;
  removePlayer: (player: SportTeamView) => void;
  selectCaptain: (player: MatchSportTeamView) => void;
  closedPreselect: () => void;
}
