import React from "react";
import { Tooltip } from "antd";
import { ReactComponent as BackIcon } from "../../../assets/icons/buttonBack.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/buttonPlus.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/buttonCheck.svg";
import { ReactComponent as CancelIcon } from "../../../assets/icons/buttonCancel.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/buttonEdit.svg";
import "./ButtonAction.css";
import { Icon } from '../../Icons/Icon';
import { icons } from '../../Icons/icons';

interface Properties {
  type: "plus" | "check" | "cancel" | "edit" | "back";
  onClick?: () => void;
  size?: number;
  tooltip?: string;
  color?: string;
}

export const ButtonAction: React.FC<Properties> = ({
  type,
  onClick,
  tooltip,
  color,
  size = 32,
}) => {

  const getButtonIcon = (): keyof typeof icons => {
    switch (type) {
      case "back":
        return "buttonBack";
      case "plus":
        return "buttonPlus";
      case "check":
        return "buttonCheck";
      case "cancel":
        return "buttonCancel";
      case "edit":
        return "buttonEdit";
      // default:
      //   //return <EditIcon className={`button-action ${type}`} />;
      //   return null;
    }
  };

  const getTooltip = (): string => {
    switch (type) {
      case "plus":
        return "Agregar uno nuevo";
      case "check":
        return "Confirmar";
      case "cancel":
        return "Cancelar";
      case "edit":
        return "Editar";
      default:
        return "";
    }
  };

  return (
    <Tooltip title={tooltip ?? getTooltip()}>
      <div onClick={onClick}>
        <Icon name={getButtonIcon()}
          className={`button-action ${type}`}
          size={size}
          color={color}
        />
      </div>
    </Tooltip>
  );
};
