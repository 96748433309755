import React, { createContext, useCallback, useMemo, useState } from 'react';
import { useAsyncCall } from '../../../hooks/useAsyncCall';
import { useMatchesService } from '../../../services/useMatchesService';
import { MatchView } from '../../../virtualclub/models/models/matches/Match.view';
import { AuthorizationHelper } from '../../../helpers/AuthorizationHelper';
import { MatchSportTeamView } from '../../../virtualclub/models/models/matchesSportTeams/MatchSportTeam.view';
import { SportTeamCategoryType } from '../../../virtualclub/models/models/sportsTeamsCategories/SportTeamCategoryType.enum';
import { MatchSportTeamStatus } from '../../../virtualclub/models/models/matchesSportTeams/MatchSportTeamStatus.enum';

interface Props {
  match?: MatchView;
}

export const CheckMatchContext = createContext<CheckMatchContextValue>(
  {} as CheckMatchContextValue
);

export const CheckMatchContextProvider: React.FC<
  React.PropsWithChildren<Props>
> = ({ children, match }) => {
  const [auth] = useState(new AuthorizationHelper());
  const { getSportsTeams, updateSportTeam, selectSportTeam } = useMatchesService();
  const [loading, setLoading] = useState<boolean>(false);
  const [enabled, setEnabled] = useState(true);

  const [matchSportTeams, setMatchSportTeams] = useState<MatchSportTeamView[]>([]);

  const loadData = useCallback(async () => {
    if (match) {

      // TODO: Cargar los jugadores seleccionados
      const selected = await getSportsTeams(match.matchId!);
      // const sortedItems = selected.sort(
      //   (a, b) => (a.sportTeamPosition ?? 0) - (b.sportTeamPosition ?? 0)
      // );
      setMatchSportTeams(selected);
    }
  }, [match, getSportsTeams]);

  const loader = useAsyncCall(loadData, [match]);

  const getMatchSportTeams = useCallback(
    (federationTeamId: string, categoryType: SportTeamCategoryType): MatchSportTeamView[] => {
      const result = matchSportTeams.filter(x => x.federationTeamId === federationTeamId && x.sportTeamType === categoryType)
      return result ?? [];
    },
    [matchSportTeams]
  );

  const changeStatus = useCallback(
    (matchSportTeamId: number): void => {
      const [result] = matchSportTeams.filter(x => x.matchSportTeamId === matchSportTeamId)
      let matchSportTeamStatus: MatchSportTeamStatus | undefined;
      if (result.matchSportTeamStatus === MatchSportTeamStatus.Absent) matchSportTeamStatus = MatchSportTeamStatus.Present;
      if (result.matchSportTeamStatus === MatchSportTeamStatus.Present) matchSportTeamStatus = MatchSportTeamStatus.Absent;
      if (matchSportTeamStatus) {
        setMatchSportTeams((prev) =>
          prev.map((p) => (p.matchSportTeamId === result.matchSportTeamId ? { ...p, matchSportTeamStatus } : p))
        );
      }

    },
    [matchSportTeams]
  );

  const updateMatchSportTeam = useCallback(
    async (player: MatchSportTeamView): Promise<boolean> => {
      const result = await updateSportTeam(player, match?.matchId ?? '');
      if (result) {
        setMatchSportTeams((values) =>
          values.map((p) => (p.matchSportTeamId !== player.matchSportTeamId ? p : player))
        );
        return true;
      }
      return false;
    },
    [match?.matchId, updateSportTeam]
  );

  const isValid = useCallback((): boolean => {
    if (!match?.matchId) {
      return false;
    }
    return (
      (matchSportTeams.length >= (match.minimumPlayers ?? 0) &&
        matchSportTeams.length <= (match?.maximumPlayers ?? 999)) ??
      false
    );
  }, [
    match?.matchId,
    match?.maximumPlayers,
    match?.minimumPlayers,
    matchSportTeams.length,
  ]);

  const selectCaptain = useCallback(
    async (player: MatchSportTeamView) => {
      await updateMatchSportTeam({ ...player, isCaptain: 1 });

      setMatchSportTeams((prevs) =>
        prevs.map((row) => ({
          ...row,
          isCaptain: row.matchSportTeamId === player.matchSportTeamId ? 1 : 0,
        }))
      );
    },
    [updateMatchSportTeam]
  );

  const updateStaff = useCallback(
    async (staff: MatchSportTeamView): Promise<boolean> => {
      let result;
      if (staff.matchSportTeamId) {
        result = await updateSportTeam(staff, match?.matchId ?? '');
      } else {
        result = await selectSportTeam(match?.matchId!, staff.sportTeamCategoryId!);
      }
      // if (result) {
      //   setStaff((prev) =>
      //     prev.map((p) => (p.sportTeamType !== staff.sportTeamType ? p : staff))
      //   );
      //   return true;
      // }
      return false;
    },
    [match?.matchId, selectSportTeam, updateSportTeam]
  );

  const captain = useMemo(() => {
    return matchSportTeams.find((x) => x.isCaptain === 1);
  }, [matchSportTeams]);

  const contextValue = useMemo<CheckMatchContextValue>(
    () => ({
      loading,
      match,
      matchSportTeams: matchSportTeams,
      isValid: isValid(),
      captain,
      enabled,
      getMatchSportTeams,
      updateMatchSportTeam,
      selectCaptain,
      changeStatus,
    }),
    [loading, match, matchSportTeams, isValid, captain, enabled, getMatchSportTeams, updateMatchSportTeam, selectCaptain, changeStatus]
  );

  return (
    <CheckMatchContext.Provider value={contextValue}>
      {children}
    </CheckMatchContext.Provider>
  );
};

export interface CheckMatchContextValue {
  loading?: boolean;
  match?: MatchView;
  matchSportTeams: MatchSportTeamView[];
  captain?: MatchSportTeamView;
  isValid: boolean;
  enabled: boolean;
  getMatchSportTeams?: (federationTeamId: string, categoryType: SportTeamCategoryType) => MatchSportTeamView[];
  updateMatchSportTeam: (player: MatchSportTeamView) => Promise<boolean>;
  selectCaptain: (player: MatchSportTeamView) => void;
  changeStatus: (matchSportTeamId: number) => void;
}
