import { Row } from "../../../components/TableCard/Row";
import { ColText } from "../../../components/TableCard/ColText";
import { ColShirt } from "../../../components/TableCard/ColShirt";
import { ColField } from "../../../components/TableCard/ColField";
import { ColButton } from "../../../components/TableCard/ColButton";
import { useContext } from "react";
import { PreSelectTeamContext } from "../preSelectTeam/PreSelectTeamContext";
import { ColTag } from "../../../components/TableCard/ColTag";
import { MatchSportTeamView } from "../../../virtualclub/models/models/matchesSportTeams/MatchSportTeam.view";

interface properties {
  value: MatchSportTeamView;
  selected?: boolean;
  onClick?: (value: MatchSportTeamView) => void;
  onChangeShirtNumber?: (value: MatchSportTeamView) => void;
  onChangePosition?: (value: MatchSportTeamView) => void;
}
export const SelectedPlayer: React.FC<properties> = ({
  value,
  selected,
  onClick,
  onChangeShirtNumber,
  onChangePosition,
}) => {
  const { removePlayer, selectCaptain, updateMatchSportTeam, enabled } =
    useContext(PreSelectTeamContext);

  function handleRemoveClick(): void {
    removePlayer?.(value);
  }

  function handleChangeShirtNumber(): void {
    if (enabled) {
      onChangeShirtNumber?.(value);
    }
  }
  function handleChangePosition(): void {
    if (enabled) {
      onChangePosition?.(value);
    }
  }
  async function handleChangeTitular(): Promise<void> {
    if (enabled) {
      await updateMatchSportTeam({
        ...(value as MatchSportTeamView),
        playerStatus: value?.playerStatus === 1 ? 2 : 1,
      });
    }
  }

  function handleSelectCaptain(): void {
    if (enabled) {
      selectCaptain?.(value);
    }
  }

  return (
    <>
      <Row id={value.sportTeamCategoryId ?? ""} justify active={selected} gap="0">
        <ColText value={value.sportTeamCode ?? ""} grayText width="70px" />
        <ColText
          value={value.sportTeamNames ?? "--"}
          width="230px"
        />
        <ColTag
          value={value.playerStatus === 1 ? "Titular" : "Suplente"}
          onClick={handleChangeTitular}
        />
        <ColButton
          icon="C"
          onClick={handleSelectCaptain}
          isSelected={value.isCaptain === 1}
        />
        <ColShirt
          value={value.sportTeamNumber?.toString()}
          onClick={handleChangeShirtNumber}
        />
        <ColField value={value.sportTeamPosition} onClick={handleChangePosition} />
        {enabled && <ColButton icon="-" onClick={handleRemoveClick} />}
      </Row>
    </>
  );
};
