import React from "react";
import { configApp } from '../../services/configApp';
import { SimpleImage } from "./SimpleImage";

interface properties {
  id?: string;
  alt?: string;
  className?: string;
  style?: React.CSSProperties;
}

export const AvatarImage: React.FC<properties> = ({ id, alt, className, style }) => {

  const url = `${configApp.avatarUrl}${id}.png`;

  return (
    <SimpleImage
      src={url}
      alt={alt}
      className={className}
      style={style}
    />
  );
};
