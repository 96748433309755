import { useState } from 'react';

import { apiDelete, apiGet, apiPatch, apiPost } from './BaseService';
import { apiRoutes } from '../constants/ApiRouteConstants';
import {
  convertToUrlParamiter
} from '../helpers/filterHelper';
import { PageResult } from '../virtualclub/models/base/PageResult';
import { SearchFilters } from '../virtualclub/models/filters/BaseFilters';
import { MatchView } from '../virtualclub/models/models/matches/Match.view';
import { SportTeamView } from '../virtualclub/models/models/sportsTeams/SportTeam.view';
import { MatchesSportsTeamsDto } from '../virtualclub/models/models/matchesSportTeams/MatchesSportsTeams.dto';
import { MatchSportTeamView } from '../virtualclub/models/models/matchesSportTeams/MatchSportTeam.view';
import { BuildUrl } from '../helpers/UrlHelper';

export const useMatchesService = () => {
  const [data, setData] = useState<PageResult<MatchView>>();
  const [current, setCurrent] = useState<MatchView>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const list = async (
    filters?: SearchFilters<MatchView>
  ) => {
    setIsLoading(true);
    setError(null);
    try {
      const url = apiRoutes.matches + convertToUrlParamiter(filters);
      const result = await apiGet<PageResult<MatchView>>(url);
      console.log(result);
      setData(result);
      if (result?.data?.length) {
        setCurrent(result.data[0]);
      } else {
        setCurrent(undefined);
      }
    } catch (err) {
      console.log(err);
      setError(err as Error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const get = async (id: string): Promise<MatchView | undefined> => {
    try {
      const model = await apiGet<MatchView>(apiRoutes.matchId.replace(':id', id));
      return model;
    } catch (er) { }
    return undefined;
  };


  const getSportsTeams = async (matchId: string, federationTeamId: string = ""): Promise<MatchSportTeamView[]> => {
    try {
      const url = BuildUrl(apiRoutes.matchSportTeamByFederationTeam, { matchId, federationTeamId });
      const model = await apiGet<MatchSportTeamView[]>(url);
      return model ?? [];
    } catch (er: any) {
      console.log(er.message);
    }
    return [];
  };

  const selectSportTeam = async (matchId: string, sportTeamCategoryId: string): Promise<MatchesSportsTeamsDto | undefined> => {
    try {
      const url = BuildUrl(apiRoutes.matchSportTeamSelected, { matchId, sportTeamCategoryId });
      const model = await apiPost<SportTeamView, MatchesSportsTeamsDto>(url);
      return model;
    } catch (er) { }
    return undefined;
  };

  const removeSportTeam = async (matchSportTeam: MatchSportTeamView): Promise<MatchesSportsTeamsDto | undefined> => {
    try {
      const param = { matchId: matchSportTeam.matchId!, matchSportTeamId: matchSportTeam.matchSportTeamId! };
      const url = BuildUrl(apiRoutes.matchSportTeamId, param)
      const model = await apiDelete<MatchesSportsTeamsDto>(url);
      return model;
    } catch (er: any) {
      console.error(er.message);
    }
    return undefined;
  };

  const updateSportTeam = async (matchSportTeam: MatchSportTeamView, matchId: string): Promise<MatchesSportsTeamsDto | undefined> => {
    try {
      const param = { matchId, matchSportTeamId: matchSportTeam.matchSportTeamId! };
      const url = BuildUrl(apiRoutes.matchSportTeamId, param);
      const model = await apiPatch<MatchSportTeamView, MatchesSportsTeamsDto>(url, matchSportTeam);
      return model;
    } catch (er: any) {
      console.error(er.message);
    }
    return undefined;
  };

  const closePreselect = async (matchId: string, federationTeamId: string): Promise<MatchesSportsTeamsDto | undefined> => {
    try {
      const param = { matchId, federationTeamId };
      const url = BuildUrl(apiRoutes.matchClosePreselect, param)
      const model = await apiPatch<MatchSportTeamView, MatchesSportsTeamsDto>(url);
      return model;
    } catch (er: any) {
      console.error(er.message);
    }
    return undefined;
  };

  return { data, current, isLoading, error, list, get, getSportsTeams, selectSportTeam, updateSportTeam, removeSportTeam, closePreselect };
}