import React, { createContext, useCallback, useMemo, useState } from "react";
import { PageResult } from "../virtualclub/models/base/PageResult";
import { useAsyncCall } from "../hooks/useAsyncCall";
import { useDebounce } from "../hooks/useDebounce";
import { SportsTeamsFilters } from "../virtualclub/models/models/sportsTeams/SportsTeams.filters";
import { useSportsTeamsService } from "../services/useSportsTeamsService";
import { SportTeamView } from "../virtualclub/models/models/sportsTeams/SportTeam.view";

import { useSportsTeamsCategoriesService } from "../services/useSportsTeamsCategoriesService";
import { SportTeamStatus } from "../virtualclub/models/models/sportsTeams/SportTeamStatus.enum";

interface Props {
  baseFilters?: SportsTeamsFilters;
}

export const SportsTeamsContextProvider: React.FC<
  React.PropsWithChildren<Props>
> = ({ children, baseFilters }) => {
  const { data, current, isLoading, error, list, select, getId } =
    useSportsTeamsService();
  const { updateCategory } = useSportsTeamsCategoriesService();

  const [filters, setFilters] = useState<SportsTeamsFilters>(baseFilters || {});
  const [editing, setEditing] = useState<boolean>(false);

  const [debouncedInput] = useDebounce({ filters }, 1000);

  const debouncedSearch = useCallback(async (): Promise<void> => {
    if (!debouncedInput) {
      return;
    }
    list({ ...filters, ...baseFilters });
  }, [baseFilters, debouncedInput, filters, list]);

  const loader = useAsyncCall(debouncedSearch, [debouncedInput]);

  const updateFilters = useCallback((newFilters?: SportsTeamsFilters) => {
    setFilters((values) => ({ ...values, ...newFilters, page: 1 }));
  }, []);

  const gotoPage = useCallback((page: number) => {
    setFilters((values) => ({ ...values, page: page }));
  }, []);

  const changePageSize = useCallback((size: number) => {
    setFilters((values) => ({ ...values, page: 1, limit: size }));
  }, []);

  const create = () => {
    // setCurrent({} as TeamMembers);
    // setEditing(true);
  };

  const edit = useCallback(() => {
    return false;
  }, []);

  const cancel = () => {
    setEditing(false);
  };

  const selected = useCallback(
    (value?: SportTeamView) => {
      select(value);
    },
    [select]
  );

  const updateStatus = useCallback(
    async (status: SportTeamStatus, reason: string

    ): Promise<boolean> => {
      if (!current?.sportTeamCategoryId) return false;

      const updatedCategory = { sportTeamCategoryStatus: status, observations: reason };
      try {
        const currentId = current.sportTeamId;
        const result = await updateCategory(
          current.sportTeamCategoryId,
          updatedCategory
        );
        if (result) {
          await debouncedSearch();
          await getId(currentId ?? "");

          return true;
        }
        return false;
      } catch (error) {
        console.error("Error updating status:", error);
        return false;
      }
    },
    [current, updateCategory, list, getId]
  );

  const contextValue = useMemo<SportsTeamsContextValue>(
    () => ({
      isLoading,
      filters,
      data,
      current,
      editing,
      selected,
      updateFilters,
      gotoPage,
      changePageSize,
      create,
      edit,
      cancel,
      getId,
      updateStatus,
    }),
    [
      isLoading,
      filters,
      data,
      current,
      editing,
      selected,
      updateFilters,
      gotoPage,
      changePageSize,
      edit,
      getId,
      updateStatus,
    ]
  );

  return (
    <SportsTeamsContext.Provider value={contextValue}>
      {children}
    </SportsTeamsContext.Provider>
  );
};

export const SportsTeamsContext = createContext<SportsTeamsContextValue>(
  {} as SportsTeamsContextValue
);

export interface SportsTeamsContextValue {
  isLoading?: boolean;
  filters?: SportsTeamsFilters;
  editing: boolean;
  data?: PageResult<SportTeamView>;
  current?: SportTeamView;

  updateFilters?: (filters?: SportsTeamsFilters) => void;
  gotoPage?: (page: number) => void;
  changePageSize?: (size: number) => void;

  create?: () => void;
  selected?: (value: SportTeamView) => void;
  edit?: () => boolean;
  cancel?: () => void;
  getId: (id: string) => Promise<SportTeamView | undefined>;
  updateStatus?: (status: SportTeamStatus, reason: string) => Promise<boolean>;
}
