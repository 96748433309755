import { Pagination } from "antd";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PartidoEstado } from "../../virtualclub/models/enums/PartidoEstado";
import { Row } from "../../components/TableCard/Row";
import { ColTag } from "../../components/TableCard/ColTag";
import { ColText } from "../../components/TableCard/ColText";
import { MatchesContext } from "../../contexts/MatchesContext";
import { routes } from "../../constants/RouteConstants";

import { ColMatch } from "../../components/TableCard/ColMatch";

interface properties {
  onChange?(model: any): void;
}
const statusStyles: { [key: number]: string } = {
  [PartidoEstado.Pendiente]: "status-pendiente",
};
export const MatchesList: React.FC<properties> = ({ onChange }) => {
  const { data, gotoPage } = useContext(MatchesContext);
  const navigate = useNavigate();

  const handleRowClick = (matchId: string) => {
    const match = data?.data?.find((m) => m.matchId === matchId);
    if (match) {
      navigate(routes.Match.replace(":id", match.matchId ?? ""));
    }
  };
  function handleChangePage(page: number, pageSize: number): void {
    gotoPage(page);
  }
  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const formatTime = (dateString: string | undefined) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  return (
    <>
      {data?.data &&
        data?.data.map((match) => (
          <Row
            id={match.matchId ?? ""}
            gender={match.gender}
            onClick={handleRowClick}
            height="80px"
            justify
          >
            <ColTag value={"1ra"} width={50} />
            <ColMatch hometeam={match.homeTeam} awayTeam={match.awayTeam} />

            <ColText
              value={formatDate(match.dateTimePlay?.toString())}
              grayText
              isDate
              width="150px"
            />
            <ColText
              value={formatTime(match.dateTimePlay?.toString())}
              grayText
              isTime
              width="150px"
            />

            <ColTag value={"Detalles"} />
          </Row>
        ))}

      {data?.limit && (data?.total ?? 0) > data?.limit && (
        <Pagination
          total={data?.total}
          pageSize={data.limit}
          current={data.page}
          onChange={handleChangePage}
        />
      )}
    </>
  );
};
