import React, { useState } from "react";
import { TeamsContextProvider } from "../../contexts/TeamsContext";
import { TeamsList } from "./TeamsList";
import { TeamCard } from "./TeamCard";
import { GenericPage } from "../../components/Screen/GenericPage";
import { ContentPage } from "../../components/Screen/ContentPage";
import { SideRightPage } from "../../components/Screen/SideRightPage";
import { TeamsSearch } from "./TeamsSearch";

const TeamsPage: React.FC = () => {
  const [isDrawerVisible, setDrawerVisible] = useState(false);

  const handleSelect = () => {
    setDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };
  return (
    <TeamsContextProvider>
      <GenericPage>
        <ContentPage>
          <TeamsSearch />
          <TeamsList onSelect={handleSelect} />
        </ContentPage>
        <SideRightPage
          isDrawerVisible={isDrawerVisible}
          onCloseDrawer={handleCloseDrawer}
        >
          <TeamCard />
        </SideRightPage>
      </GenericPage>
    </TeamsContextProvider>
  );
};

export default TeamsPage;
