import React, { useEffect, useMemo } from "react";
import { DefaultOptionType } from "antd/es/select";
import {
  SelectExt,
  SelectExtProps,
  SelectItem,
} from "../../components/SelectExt";
import { useCategoriesService } from "../../services/CategoriesService";

interface properties extends SelectExtProps {
  federationId?: string;
  sportId?: number;
  showAll?: boolean;
  textAll?: string;
}

export const SelectCategory: React.FC<properties> = ({
  federationId,
  sportId,
  showAll = false,
  textAll = showAll ? "Todas las categorías" : "Categoría",
  value,
  readOnly,
  type,
  onChange,
  ...props
}) => {
  const { isLoading, list, data } = useCategoriesService();

  useEffect(() => {
    if (federationId && sportId) {
      list({ accountId: federationId, sportId });
    }
  }, [federationId, sportId]);

  const options = useMemo(() => {
    const its: SelectItem[] =
      data?.data?.map((category) => {
        return {
          value: category.categoryId,
          label: category.categoryShort,
        } as SelectItem;
      }) ?? [];
    if (showAll) {
      its.unshift({ value: -1, label: textAll });
    }
    return its;
  }, [data?.data, showAll, textAll]);

  function onChangeValue(
    value: string | number,
    option: DefaultOptionType | DefaultOptionType[]
  ): void {
    const selectedCategory = data?.data?.find(
      (category) => category.categoryId === value
    );
    onChange?.(selectedCategory?.categoryId, option);
  }

  return (
    <SelectExt
      options={options}
      readOnly={readOnly}
      value={value}
      placeholder={textAll}
      onChange={onChangeValue}
      loading={isLoading}
      type={type}
    />
  );
};
